import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import { getDataRental } from '../../../services/rentalSettings/rentalItem';
import { getDataTypeOfTaxes } from '../../../services/dynamicList/typesOfTaxes';
import { getDataDiscounts } from '../../../services/dynamicList/discounts';
// import { getData } from "../../../services/crm/clients";
import { AiOutlineDelete } from "react-icons/ai";
import { differenceInDays } from 'date-fns';
import { BiAddToQueue } from "react-icons/bi";
import { getDataAgents } from '../../../services/dynamicList/agents';
import { addData, getData /*, deleteData, editData*/ } from "../../../services/crm/clients";
import ModalC from '../../modal';
import FormClient from '../../clients/formClient';
import { formatDate } from '../../../services/Tools';
import { rentalDurationHours, rentalDurationDays, durationTypes } from '../../../services/Tools';
import { UserAuth } from '../../../context/AuthContext';
import { getDataOneUser } from '../../../services/auth/users';
import { roleVisit } from '../../../services/Tools';

function FormConfig({ title, action, lastData, setModal, clients }) {

    const [id, setId] = useState("");
    const [client, setClient] = useState({ value: "", label: "" });
    const [clientName, setClientName] = useState("");
    const [client_name, setClient_Name] = useState("");
    // const [rental, setRental] = useState("");
    const [rental, setRental] = useState([]);
    const [note, setNote] = useState("");
    const [paid, setPaid] = useState(false);
    const [taxes, setTaxes] = useState("");
    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState(0);
    const [amount, setAmount] = useState("");
    const [amountPaid, setAmountPaid] = useState(""); 
    const [taxAmount, setTaxAmount] = useState("");
    const [total, setTotal] = useState("");
    const [balance, setBalance] = useState("");
    const [agents, setAgents] = useState([]);
    const [bookingType, setBookingType] = useState("Directly");
    const [modalClient, setModalClient] = useState(false);
    const [titleModalClient, setTitleModalClient] = useState('');
    const [durationType, setDurationType] = useState('');
    const [duration, setDuration] = useState('');
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState(""); 
    const [tipAmount, setTipAmount] = useState(0);

    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [rentalListAux, setRentalListAux] = useState([]);
    const [rentalList, setRentalList] = useState([{ value: '', label: '' }]);

    const [newClient, setNewClient] = useState(false);
    const [newClientData, setNewClientData] = useState("");

    const [taxListAux, setTaxListAux] = useState([]);
    const [taxList, setTaxList] = useState([{ value: '', label: '' }]);

    const [discountListAux, setDiscountListAux] = useState([]);
    const [discountList, setDiscountList] = useState([{ value: '', label: '' }]);

    const [clientListAux, setClientListAux] = useState([]);
    const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);

    const [agentsListAux, setAgentsListAux] = useState([]);
    const [agentsList, setAgentsList] = useState([{ value: '', label: '' }]); 
    
    const [pickupDate, setPickupDate] = useState("");
    const [dropOffDate, setDropOffDate] = useState("");
    const [pickupTime, setPickupTime] = useState("");
    const [dropOffTime, setDropOffTime] = useState("");

    const [cardSurcharge, setCardSurcharge] = useState(false);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0);
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);

    const { user, logOut } = UserAuth();
    const [userInfo, setUserInfo ] = useState('');

    //Use by get all the List
     useEffect(() => {
        if (user != null) {

        getDataRental(setRentalListAux);
        getDataTypeOfTaxes(setTaxListAux);
        getDataDiscounts(setDiscountListAux);
        getDataAgents(setAgentsListAux);
        
        if(clients?.rows?.length > 0){
            setClientListAux(clients);
        }else{
            getData(setClientListAux);
        }

        const getUserData = async () => {
            const userDocSnap = await getDataOneUser(user.uid);
            if(userDocSnap?.length > 0){
            // if(userDocSnap[0?.role !== roleVisit]){
                setUserInfo(userDocSnap[0]);
            // }
            // else{
            //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
            //   window.location.href = '/';
            // }
            }
        };
    
        getUserData();

        }
        
    }, []);

    //Use by rentals List
    useEffect(() => {
        if (rentalListAux?.rows?.length > 0) {
            var aux = [];
            rentalListAux?.rows?.forEach(element => {
                aux.push({ value: element?.color, label: element?.rental, priceByDay: element?.priceByDay, priceByHour: element?.priceByHour, quantity: 1 });
            });
            setRentalList(aux);
        }
    }, [rentalListAux]);

    //Use by taxes
    useEffect(() => {
        if (taxListAux?.rows?.length > 0) {
            var aux = [];
            taxListAux?.rows?.forEach(element => {                
                if(element?.type !== "Fuel Surcharge"){
                    aux.push({ value: element?.tax, label: element?.type });
                }
            });
            setTaxList(aux);
        }
    }, [taxListAux]);

    //Use by discount
    useEffect(() => {
        if (discountListAux?.rows?.length > 0) {
            var aux = [];
            discountListAux?.rows?.forEach(element => {                
                aux.push({ value: element?.discount, label: (element?.type + ( " " + element?.discount +"%")) });
            });
            setDiscountList(aux);
        }
    }, [discountListAux]);

    //Use by client List
    useEffect(() => {
        if (clientListAux?.rows?.length > 0) {
            var aux = [];
            clientListAux?.rows?.forEach(element => {   
                aux.push({ value: element?.id, label: element?.client }); 
            });
            setClientList(aux);
        }
    }, [clientListAux]);

    //Use by agents
    useEffect(() => {
        if (agentsListAux?.rows?.length > 0) {
            var aux = [];
            agentsListAux?.rows?.forEach(element => {
                aux.push({ value: element?.id, label: element?.name, commission: element?.commission });
            });
            setAgentsList(aux);
        }
    }, [agentsListAux]);

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            setClient(lastData?.client);
            setNote(lastData?.note);
            //setRental(lastData.rental);
            // setRental({ value: lastData.rental.label, label: lastData.rental.label });
            setNewClient(lastData?.newClient);
            setNewClientData(lastData?.newClientData);
            setClientName(lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData);
            setClient_Name(lastData?.client_name ? lastData?.client_name : (lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData));
            setPaid(lastData?.paid ? lastData?.paid : false);
            setRefund(lastData?.refund ? lastData?.refund : false);
            setRefundAmount(lastData?.refundAmount ? lastData?.refundAmount : 0);
            setTipAmount(lastData?.tipAmount ? lastData?.tipAmount : 0);
            setAmount(lastData?.amount ? lastData?.amount : 0);
            setAmountPaid(lastData?.amountPaid ? lastData?.amountPaid : 0);
            setTaxAmount(lastData?.taxAmount ? lastData?.taxAmount : 0);
            setTotal(lastData?.total ? lastData?.total : 0);
            setBalance(lastData?.balance ? lastData?.balance : 0);
            setTaxes({ value: lastData?.taxes?.value, label: lastData?.taxes?.label });
            setDiscount({ value: lastData?.discount?.value, label: lastData?.discount?.label });
            setDiscountAmount(lastData?.discountAmount);
            setBookingType(lastData?.bookingType ? lastData?.bookingType : "Directly");
            setPickupDate(lastData?.pickupDate); 
            setDropOffDate(lastData?.dropOffDate);
            setPickupTime(lastData?.pickupTime);
            setDropOffTime(lastData?.dropOffTime);
            setDurationType(lastData?.durationType);
            setDuration(lastData?.duration);
            setCardSurcharge(lastData?.cardSurcharge ? lastData?.cardSurcharge : false);
            setCardSurchargeAmount(lastData?.cardSurchargeAmount ? lastData?.cardSurchargeAmount : 0);
            setCardSurchargePercentage(lastData?.cardSurchargePercentage ? lastData?.cardSurchargePercentage : 2.4); 


            if(lastData?.agents?.value){
                setAgents({ value: lastData?.agents?.value, label: lastData?.agents?.label, commission: lastData?.agents?.commission });
            }

            if (Array.isArray(rental)) {
                let data = [...rental];
                for (let i = 0; lastData?.rental?.length > i; i++) {
                    data[i] = lastData?.rental[i];
                }
                setRental(data);
            } else {
                let data = [];
                data[0] = lastData?.rental[0];
                setRental(data);
            }
        }
    }, [lastData])

    const calculateTotal = (value, tax, disco, tip, card = cardSurcharge) => {

        // let amo = 0; //Monto sin impuestos ni descuento
        let imp = 0; //Captura el impuesto
        let tot = 0; //total con los impuestos y descuento
        let bal = 0; //Balance = total - paid
        let paid = 0; //El monto pagado previamente
        let dis = 0; //El descuento que agreguen
        let car = 0; //Se guarda el monto del card surcharge

        if(tax !== ""){
            
            if(lastData){
                bal = lastData?.balance ? lastData?.balance : 0;
                paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;                 
            }

            if(value !== ""){
                setAmount(parseFloat(value));
                if(card){
                    car = parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
                }

                if(disco?.value){
                    dis = parseFloat(value) * (parseFloat(disco?.value) / 100);
                }

                // imp = ((parseFloat(value) + parseFloat(car) - parseFloat(dis))) * (parseFloat(tax?.value) / 100); 
                imp = ((parseFloat(value) - parseFloat(dis))) * (parseFloat(tax?.value) / 100); 
                tot = parseFloat(value) + parseFloat(imp) + parseFloat(car) - parseFloat(dis); 
                bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);

            }else{
                setAmount("");
            }

            setCardSurchargeAmount(parseFloat(car.toFixed(2)));
            setDiscountAmount(parseFloat(dis.toFixed(2)));
            setTaxAmount(parseFloat(imp.toFixed(2)));
            setTotal(parseFloat(tot.toFixed(2)));
            setAmountPaid(parseFloat(paid.toFixed(2)));
            setBalance(parseFloat(bal.toFixed(2)));

        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    const automaticTotal = (rental, tax, disco, startDate, endDate, startTime, endTime, dura, tip, card = cardSurcharge) => {

        let amo = 0; //Monto sin impuestos ni descuento
        let imp = 0; //Captura el impuesto
        let tot = 0; //total con los impuestos y descuento
        let bal = 0; //Balance = total - paid
        let paid = 0; //El monto pagado previamente
        let dis = 0; //El descuento que agreguen
        let days = 0; // Guarda la diferencia de dias
        let hours = 0; //Guarda la diferencia de horas
        let start = ""; //Guarda el hora de inicio
        let end = ""; //Guarda el hora final
        let car = 0; //Se guarda el monto del card surcharge

        if(lastData){
            bal = lastData?.balance ? lastData?.balance : 0;
            paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;                 
        }

        if(rental?.length > 0){
            rental.forEach(element => {
                
                if(element !== ""){

                    if(durationType?.value === "Daily"){
                        if(startDate){
                            if(dura?.value){
                                days = differenceInDays(new Date(endDate), new Date(startDate));  
                                if(days > 0){
                                    amo = parseFloat(amo) + ((parseFloat(days) * parseFloat(element?.priceByDay)) * parseFloat(element?.quantity));
                                }

                            }
                        }
            
                    } else if(durationType?.value === "Hourly"){
                        if(startTime){
                            if(dura?.value){
                                //CALCULA LA DIFERENCIA DE HORAS
                                // const [startHourPart, startMinutePart] = startTime.split(':');
                                // const [endHourPart, endMinutePart] = endTime.split(':');
                            
                                // const startDate = new Date(0, 0, 0, parseInt(startHourPart), parseInt(startMinutePart));
                                // const endDate = new Date(0, 0, 0, parseInt(endHourPart), parseInt(endMinutePart));
                            
                                // if (endDate < startDate) {
                                //   endDate.setDate(endDate.getDate() + 1);
                                // }
                            
                                // const diffInMilliseconds = Math.abs(endDate - startDate);
                                // const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
                                // const hours = Math.floor(diffInMinutes / 60);
                                // const minutes = diffInMinutes % 60;

                                const hours = dura?.value;                            
                                if(startTime){
                                    amo = parseFloat(amo) + ((parseFloat(hours) * parseFloat(element?.priceByHour)) * parseFloat(element?.quantity));
                                }
                            }
                        }
                    }
                    
                    if(amo > 0){
                        if(disco?.value){
                            dis = parseFloat(amo) * (parseFloat(disco?.value) / 100);
                        }

                        if(card){
                            car = parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100);
                        }
    
                        if(tax !== ""){
                            // imp = ((parseFloat(amo) - parseFloat(dis) + parseFloat(car))) * (parseFloat(tax?.value) / 100); 
                            imp = ((parseFloat(amo) - parseFloat(dis))) * (parseFloat(tax?.value) / 100); 
                        }
                    }
    
                    tot = parseFloat(amo) + parseFloat(car) + parseFloat(imp) - parseFloat(dis); 
                    bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);
    
                    setCardSurchargeAmount(parseFloat(car.toFixed(2)));
                    setDiscountAmount(parseFloat(dis.toFixed(2)));
                    setAmount(parseFloat(amo.toFixed(2)));
                    setTaxAmount(parseFloat(imp.toFixed(2))); 
                    setTotal(parseFloat(tot.toFixed(2)));
                    setAmountPaid(parseFloat(paid.toFixed(2)));
                    setBalance(parseFloat(bal.toFixed(2)));

                }
                
            });
        }

    }

    const addInputFieldRental = (e) => {
        
        // if(taxes?.value !== ""){
            e.preventDefault();
            setRental([...rental, ""]);
        // }else{
        //     Swal.fire({
        //         icon: "warning",
        //         title: "Error!",
        //         text: "Please, choose the tax rate first",
        //         confirmButtonColor: '#173d38',
        //         confirmButtonText: "OK",
        //     });
        // }
    }

    const handleInputChangeRental = (index,extra,e) => {
        let data = [...rental];
        data[index] = extra;

        if(data[index]){
            data[index].quantity = e ? e : 1;
            // setQuantity(quantity);
        }

        setRental(data);
        automaticTotal(data, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge);
    }

    const removeFieldsRental = (index) => {
        let data = [...rental];
        data.splice(index, 1);
        setRental(data);

        automaticTotal(data, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge);
        // automaticTotal(shuttle,nop,taxes,data,fuelSurcharge,discount);
    }

    const changeBookingType = (e) => {
        if(e === "Directly"){
            setAgents([]);
            setBookingType("Directly");
        }else{
            setBookingType("Agent");
        }
    }

    const checkData = (data) => {

        let success = true;
        let rentalNull = false;

        clientListAux?.rows?.forEach(element => {
            if(element?.client === data?.client?.label){
                data['client'] = { value: element?.id, label: element?.client };
                setClient({ value: element?.id, label: element?.client });
                setClientName(element?.client);
                setClient_Name(element?.client); 
            }

        });

        if (durationType !== '' && duration !== '' && rental !== '' && pickupDate !== '' && dropOffDate !== '' && pickupTime !== '' && dropOffTime !== '' && taxes !== '' && amount !== '') {
            if(bookingType === "Agent" && agents?.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It's necessary to choose the agent.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                }); 
                success = false;
            }

            if(rental?.length < 1){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: 'Rental item required',
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }else{
                rental.forEach(element => {
                    if(element === ''){
                        success = false;
                        rentalNull = true;
                    }
                });
            }

            if(rentalNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a rental item.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }

            if(!data?.newClient){
                if(data?.client?.value === ""){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Client information has not been added.",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to register the client (CRM Information).",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }
            
            if(success){
                if(balance < 0){
                    Swal.fire({
                        title: 'Negative Balance',
                        text: 'Are you sure about this action?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                      }).then((result) => {
                        if (result.isConfirmed){
                            data['refund'] = (parseFloat(data?.balance) + parseFloat(data?.amountPaid)) == 0 ? true : false;
                            data['refundAmount'] = parseFloat(balance);
                            data['paid'] = false;
                            action(data, lastData);
                        }
                      });
                }else{
                    if(balance === 0){
                        data['paid'] = true;
                    }else{
                        data['paid'] = false;
                    }
                    action(data, lastData);
                }
            }

        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "All fields with (*) are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }
    }

    const openClientModal = () => {
        setModalClient(true);
        setTitleModalClient('Add Client');
    };

    const actionClient = (data) => {
        // handleClearRows();
        data['owner'] = data['owner'] ? data['owner'].value : "";
        setNewClientData(data['client']);

        addData(data, true);
        setModalClient(false);

        getData(setClientListAux);
        setClient({ value: '', label: data['client'] });
        setClientName(data['client']);
        setClient_Name(data['client']);
        
        setNewClient(false);
        setNewClientData("");

    }

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const changeDuration = (dura, pickDate, pickTime) => {

        if(durationType?.value === "Daily"){
            if(pickDate){
                if(dura?.value){
                    const newDate = new Date(pickDate);
                    newDate.setDate(newDate.getDate() + (dura.value+1));
                    setPickupDate(pickDate);
                    setDropOffDate(formatDate(newDate, "yyyy-MM-dd"));
                    automaticTotal(rental, taxes, discount, pickDate, formatDate(newDate, "yyyy-MM-dd"), pickTime, pickTime, dura, tipAmount, cardSurcharge); 
                }else{
                    setPickupDate(pickDate);
                    setDropOffDate(pickDate);
                    automaticTotal(rental, taxes, discount, pickDate, pickDate, pickTime, pickTime, dura, tipAmount, cardSurcharge);
                }
            }
            setPickupTime(pickTime);
            setDropOffTime(pickTime);

        } else if(durationType?.value === "Hourly"){
            
            if(pickTime){
                if(dura?.value){
                    const [hours, minutes] = pickTime.split(':');
                    const newHours = parseInt(hours, 10) + (dura?.value * 3);
    
                     // Asegurarse de que las horas no excedan 24
                    const adjustedHours = newHours >= 24 ? newHours - 24 : newHours;
                    const newTime = `${adjustedHours.toString().padStart(2, '0')}:${minutes}`;
    
                    setPickupTime(pickTime);
                    setDropOffTime(newTime);
                    automaticTotal(rental, taxes, discount, pickDate, pickDate, pickTime, newTime, dura, tipAmount, cardSurcharge); 
                }else{
                    setPickupTime(pickTime);
                    setDropOffTime(pickTime);
                    automaticTotal(rental, taxes, discount, pickDate, pickDate, pickTime, pickTime, dura, tipAmount, cardSurcharge); 
                }
            }
            setPickupDate(pickDate);
            setDropOffDate(pickDate);
        }
        setDuration(dura); 
    }
    
    const changeDurationType = (e) => {
        setDuration("");
        setDurationType(e);
    }

    return (
        <>
        <div>
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-4'>

                    <Form.Group className="mb-3" controlId="formNoRegister">
                    <div className="d-inline-flex">
                        <Form.Label>Is the client registered?</Form.Label>
                        <h5 className="required">*</h5>
                    </div>
                    <div className="d-block">
                        <div style={{display: "-webkit-box"}}>
                        <input
                            style={{ margin: "7px" }}
                            type="checkbox"
                            checked={newClient === false}
                            onChange={() => { setNewClient(false); setNewClientData(""); }}
                        />Yes
                        <input
                            style={{ margin: "7px" }}
                            type="checkbox"
                            checked={newClient === true}
                            onChange={() => { setNewClient(true); setClient({ value: "", label: "" }); }}
                        />No
                        { newClient ? (
                            <div style={{marginLeft: "auto"}}>
                                <Button className={'color-green rounded-circle'} onClick={openClientModal}><BiAddToQueue></BiAddToQueue></Button>
                            </div>
                        ) : (
                            ""
                        )}
                        </div>
                    </div>
                    </Form.Group>

                    { !newClient ? (
                    <Form.Group className="mb-3" controlId="formClientName">
                        <div className="d-inline-flex">
                            <Form.Label>Client name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={clientList} value={client}
                            onChange={(e) => {setClient(e); setClientName(e?.label); setClient_Name(e?.label); }} />

                    </Form.Group>
                    ) : (
                        <Form.Group className="mb-3" controlId="formNewClient">
                        <div className="d-inline-flex">
                            <Form.Label>Unregistered client name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the client name" 
                            value={newClientData}
                            onChange={(e) => { setNewClientData(e.target.value); setClientName(e.target.value); setClient_Name(e.target.value); }}
                            onKeyDown={(e) => keypress(e)}
                        />

                        </Form.Group> 
                    )}

                    {/* <Form.Group className="mb-3" controlId="formRental">
                        <div className="d-inline-flex">
                            <Form.Label>Rental item</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={rentalList} value={rental}
                            onChange={(e) => { setRental(e); automaticTotal(e, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration); }} />

                    </Form.Group>   */}


                    <div className='row'>
                        <div className='col-md-6'>
                        <Form.Group className="mb-3">
                            <div className="d-inline-flex">
                                <Form.Label>Duration type</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Select options={durationTypes} value={durationType} 
                                onChange={(e) => changeDurationType(e)} />
                        </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            {durationType !== "" ? (
                                durationType?.value === "Hourly" ? (
                                    <Form.Group className="mb-3">
                                        <div className="d-inline-flex">
                                            <Form.Label>Duration</Form.Label>
                                            <h5 className="required">*</h5>
                                        </div>
                                        <Select options={rentalDurationHours} value={duration}
                                            onChange={(e) => { changeDuration(e, pickupDate, pickupTime);}} />

                                    </Form.Group>
                                ) : (
                                    <Form.Group className="mb-3">
                                        <div className="d-inline-flex">
                                            <Form.Label>Duration</Form.Label>
                                            <h5 className="required">*</h5>
                                        </div>
                                        <Select options={rentalDurationDays} value={duration}
                                            onChange={(e) => { changeDuration(e, pickupDate, pickupTime);}} />
                                    </Form.Group>
                                )
                            ) : ("")}
                        </div>
                    </div>


                    <Form.Group className="mb-3">
                        <div className='flex d-flex justify-content-between mb-2'>
                            <div className="d-inline-flex">
                                <Form.Label>Rental item</Form.Label>
                                <h5 className="required">*</h5>
                            </div>
                            <Button className={'color-green rounded-circle'} onClick={addInputFieldRental}><BiAddToQueue></BiAddToQueue></Button>
                        </div>
                        {rental.map((rental, index) => (
                            <div key={index} className="input-group mb-3">
                                {/* <span className="input-group-text">{`Extra ${index + 1}`}</span> */}
                                
                                <Select options={rentalList} className="form-control"
                                    id={`renta-${index + 1}`}
                                    value={rental}
                                    onChange={(e) => handleInputChangeRental(index, e,document.getElementById(`idRenta-${index}`).value)} />

                                <Form.Control type="text" key={index} id={`idRenta-${index}`}
                                    placeholder={rental?.quantity}
                                    style={{maxWidth:"20%", textAlign:"center"}}
                                    onChange={(e) => handleInputChangeRental(index,rental,e.target.value)}
                                    onKeyDown={(e) => keypress(e)}/> 
                                
                                <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c", zIndex: 0 }} onClick={() => removeFieldsRental(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                            </div>
                        ))}
                    </Form.Group>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formPickupDate">
                                <div className="d-inline-flex">
                                    <Form.Label>Pickup date</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="date"
                                    value={pickupDate}
                                    onChange={(e) => durationType?.value ? (changeDuration(duration, e.target.value, pickupTime)) : (setPickupDate(e.target.value), setDropOffDate(e.target.value))}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formDropOffDate">
                                <div className="d-inline-flex">
                                    <Form.Label>Drop off date</Form.Label>
                                    {/* <h5 className="required">*</h5> */}
                                </div>

                                <Form.Control type="date"
                                    value={dropOffDate}
                                    onChange={(e) => { setDropOffDate(e.target.value); automaticTotal(rental, taxes, discount, pickupDate, e.target.value, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge); }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group> 
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formPickupTime">
                                <div className="d-inline-flex">
                                    <Form.Label>Pickup time</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="time"
                                    value={pickupTime}
                                    onChange={(e) => durationType?.value ? (changeDuration(duration, pickupDate, e.target.value)) : (setPickupTime(e.target.value), setDropOffTime(e.target.value))}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formDropOffTime">
                                <div className="d-inline-flex">
                                    <Form.Label>Drop off time</Form.Label>
                                    {/* <h5 className="required">*</h5> */}
                                </div>

                                <Form.Control type="time"
                                    value={dropOffTime}
                                    onChange={(e) => { setDropOffTime(e.target.value); automaticTotal(rental, taxes, discount, pickupDate, dropOffDate, pickupTime, e.target.value, duration, tipAmount, cardSurcharge); }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                        </div>
                    </div>

                </div>

                <div className='col-md-4'> 

                <Form.Group className="mb-3" controlId="formTaxRate">
                        <div className="d-inline-flex">
                            <Form.Label>Tax rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={taxList} value={taxes}
                            onChange={(e) => {
                                setTaxes(e); (amount ? calculateTotal(amount,e, discount, tipAmount, cardSurcharge) : automaticTotal(rental, e, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge));
                            }} />

                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formDiscount">
                        <div className="d-inline-flex">
                            <Form.Label>Discount</Form.Label>
                        </div>
                        <div className="d-flex">
                            <div style={{width: "90%"}}>
                                <Select options={discountList} value={discount}
                                        defaultValue={discountList[0]}
                                    onChange={(e) => {
                                        setDiscount(e); (amount ? calculateTotal(amount, taxes, e, tipAmount, cardSurcharge) : automaticTotal(rental, taxes, e, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge));
                                    }} 
                                />
                            </div>

                            { discount?.value !== "" ? (
                                <div style={{width: "10%"}}>
                                    <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} 
                                    onClick={() => { setDiscount({ value: "", label: "" }); ( amount ? calculateTotal(amount, taxes, { value: "", label: "" }, tipAmount, cardSurcharge) : 
                                    automaticTotal(rental, taxes, { value: "", label: "" }, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, cardSurcharge)); }}>
                                    <AiOutlineDelete ></AiOutlineDelete></Button> 
                                </div>
                            ) : (<></>)}
                        </div>

                    </Form.Group> 

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>Add Card Surcharge?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === true}
                                onChange={() => { 
                                    setCardSurcharge(true); 
                                    automaticTotal(rental, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, true);
                                }}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === false}
                                onChange={() => { 
                                    setCardSurcharge(false); 
                                    setCardSurchargeAmount(0); 
                                    automaticTotal(rental, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, tipAmount, false);
                                }}
                            />No
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>                        
                                {cardSurcharge ? ("The Card Surcharge is " + cardSurchargePercentage +"%") : ("")}
                            </Form.Label>
                        </div>
                    </Form.Group>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTotal">
                                <div className="d-inline-flex">
                                    <Form.Label>Amount</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={amount}
                                        // readOnly={balance === 0 && lastData ? (true) : (false)}
                                        onChange={(e) => calculateTotal(e.target.value,taxes, discount, tipAmount, cardSurcharge)}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTip">
                                <div className="d-inline-flex">
                                    <Form.Label>Tip</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={tipAmount}
                                        onChange={(e) => { setTipAmount(e.target.value); (amount ? 
                                            calculateTotal(amount, taxes, discount, (e.target.value ? e.target.value : 0), cardSurcharge) : 
                                            automaticTotal(rental, taxes, discount, pickupDate, dropOffDate, pickupTime, dropOffTime, duration, (e.target.value ? e.target.value : 0), cardSurcharge) )}}
                                        onKeyDown={(e) => keypress(e)}
                                    />  
                                </div>

                            </Form.Group> 
                        </div>
                    </div>

                    <div className='row'>
                            { cardSurchargeAmount > 0  ? (
                                <div className='col-md-6'>
                                    <Form.Group className="mb-3" controlId="formCardSurchargeAmount">
                                        <div className="d-inline-flex">
                                            <Form.Label>Card Surcharge</Form.Label>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <span style={{ marginRight: '5px' }}>$</span>
                                            <Form.Control type="number" placeholder="0"
                                                value={parseFloat(cardSurchargeAmount).toFixed(2).padStart(4, '0')}
                                                readOnly
                                                // onChange={(e) => setCardSurchargeAmount(e.target.value)}
                                                onKeyDown={(e) => keypress(e)}
                                            /> 
                                        </div>
                                    </Form.Group> 
                                </div>
                            ) : ("")}

                            { taxAmount ? (
                                <div className='col-md-6'>

                                <Form.Group className="mb-3" controlId="formTaxAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Tax amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(taxAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setTaxAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div>    
                                </Form.Group>
                                </div>
                            ) : ("")}

                            { discountAmount ? (
                                <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formDiscountAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Discount amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(discountAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setDiscountAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div>
                                </Form.Group>
                                </div>
                            ) : ("")}
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTotal">
                                <div className="d-inline-flex">
                                    <Form.Label>Total</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(total).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setTotal(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formAmountPaid">
                                <div className="d-inline-flex">
                                    <Form.Label>Amount paid</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(amountPaid).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setAmountPaid(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group> 
                        </div>
                    </div>

                    <Form.Group className="mb-3" controlId="formBalance">
                        <div className="d-inline-flex">
                            <Form.Label>Balance</Form.Label>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span style={{ marginRight: '5px' }}>$</span>
                            <Form.Control type="number" placeholder="0"
                                value={parseFloat(balance).toFixed(2).padStart(4, '0')}
                                readOnly
                                // onChange={(e) => setBalance(e.target.value)}
                                onKeyDown={(e) => keypress(e)}
                            />
                        </div>
                    </Form.Group>

                </div>

                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="formBookingType">
                        <div className="d-inline-flex">
                            <Form.Label>Rental type</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={bookingType === "Directly"}
                                onChange={() => changeBookingType("Directly")}
                            />Directly
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={bookingType === "Agent"}
                                onChange={() => changeBookingType("Agent")}
                            />Agent
                        </div>
                    </Form.Group> 

                    { bookingType === "Agent" ? 
                    (
                    <Form.Group className="mb-3" controlId="formAgents">
                        <div className="d-inline-flex">
                            <Form.Label>Agent</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={agentsList} value={agents}
                            onChange={(e) => setAgents(e)} />

                    </Form.Group>
                    ) : ("")} 

                    <Form.Group className="mb-3" controlId="formNote">
                        <div className="d-inline-flex">
                            <Form.Label>Note</Form.Label>
                            {/* <h5 className="required">*</h5> */}
                        </div>

                        <Form.Control as="textarea" rows={18} placeholder="Here you can add important things"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />

                    </Form.Group>

                </div>

                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                { userInfo?.role !== roleVisit ? (
                    <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                        var data = {
                            id,
                            client,
                            clientName,
                            client_name,
                            newClientData,
                            newClient,
                            rental,
                            bookingType,
                            agents,
                            refund,
                            refundAmount,
                            tipAmount,
                            note,
                            paid,
                            taxes,
                            discount,
                            discountAmount,
                            amount,
                            amountPaid,
                            taxAmount,
                            total, 
                            balance,
                            pickupDate,
                            dropOffDate,
                            pickupTime,
                            dropOffTime,
                            duration,
                            durationType,
                            cardSurcharge,
                            cardSurchargeAmount,
                            cardSurchargePercentage,
                        };
                        checkData(data);
                    }
                    }>
                        Save
                    </Button>
                ) : ("")}
            </div>
        </form>

        <ModalC
        show={modalClient}
        onHide={() => setModalClient(false)}>
        <FormClient title={titleModalClient} lastData={{client: newClientData}} textButton={titleModalClient} action={actionClient} setModal={setModalClient}></FormClient>
        </ModalC>
        </div>

        </>
    );
}

export default FormConfig;