import { collection, onSnapshot } from "firebase/firestore";
import { toast } from "react-toastify"; 
import { auth, db } from "../firebase";
import { server } from './serverName/Bookings';
import { getDataItineraryById } from '../services/localStorage/storage';
import { privateCharter } from '../services/Tools';
import { addDataNotifications, deleteDataNotifications, getOneNotificationData } from '../services/ConfigNotifications';

// Esta función inicializa los listeners en varias colecciones
export const initializeBookingListener = (callback) => {
    if (auth.currentUser != null) { // Verifica si el usuario está autenticado
        const bookingsCollection = collection(db, server + '_reservations');
        const adventuresCollection = collection(db, server + '_adventures');
        const rentalsCollection = collection(db, server + '_rentals');

        // Listener para bookings
        const unsubscribeBookings = onSnapshot(bookingsCollection, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                // if (change.type === "added") {
                    const bookingData = change.doc.data();

                    // Obtenemos la fecha actual y la fecha de una semana en el futuro
                    const currentDate = new Date();
                    const futureDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000); // 7 días en milisegundos
                    const pastDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 días en milisegundos
                    const todayDate = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 días en milisegundos

                    // Le damos un formato Date a la fecha de inicio (startDate)
                    const startDate = new Date(bookingData?.startDate); // Suponemos que startDate es una fecha en el formato correcto
                    // Convertir created_date desde un timestamp de Firebase a un objeto Date
                    const created_date = bookingData?.created_date ? new Date(bookingData.created_date.seconds * 1000) : new Date(0);

                    
                    //Verifica que la fecha no sea mayor a un mes(30 dias) anterior
                    if (startDate >= pastDate) {
                        //Verifica que la fecha sea dos semanas a futuro del inicio de la actividad
                        if (startDate <= futureDate) {

                            // Si la fecha de inicio está dentro de una semana, entonces verificamos el balance
                            if (bookingData.balance > 0) {
                                //////////////////// callback({ ...bookingData, type: "booking" });

                                let idBooking = bookingData?.id + "-booking";
                                const truncateText = (text) =>
                                    text?.length > 20 ? `${text.slice(0, 16)}...` : text;
                                  
                                const clientNameData = truncateText(
                                    bookingData?.clientName || bookingData?.newClientData
                                );

                                let data = {
                                    idService: bookingData?.id,
                                    title: `Balance due`,
                                    message: `${clientNameData} is owing $${bookingData?.balance}`,
                                    type: `booking`,
                                    postponed: false,
                                    postponed_date: ``,
                                }

                                addDataNotifications(idBooking, data, false, false);

                                // try {
                                //     const currentData = await fetchCurrentNotificationData(idBooking);
                            
                                //     let data = {
                                //         idService: bookingData?.id,
                                //         title: `Balance due`,
                                //         message: `${clientNameData} is owing $${bookingData?.balance}`,
                                //         type: `booking`,
                                //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                                //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                                //     };
                            
                                //     addDataNotifications(idBooking, data, false, false);

                                // } catch (error) {
                                //     console.error("Error al crear la notificación:", error);
                                // }

                                
                                //Si es un viaje privado, entonces ingresa a verificar que tenga itinerario
                                if(bookingData?.shuttle?.label === privateCharter){
                                    const auxI = await getDataItineraryById(bookingData?.id);
                                    let idItinerary = bookingData?.id + "-booking-itinerary";

                                    if(auxI?.length === 0){
                                        ///////////////////callback({ ...bookingData, type: "booking-itinerary" });

                                        let data = {
                                            idService: bookingData?.id,
                                            title: `Unrecorded itinerary`,
                                            message: `${clientNameData}'s unrecorded itinerary.`,
                                            type: `booking-itinerary`,
                                            postponed: false,
                                            postponed_date: ``,
                                        }
                                        addDataNotifications(idItinerary, data, false, false);

                                        // try {
                                        //     const currentData = await fetchCurrentNotificationData(idItinerary);
                                    
                                        //     let data = {
                                        //         idService: bookingData?.id,
                                        //         title: `Unrecorded itinerary`,
                                        //         message: `${clientNameData}'s unrecorded itinerary.`,
                                        //         type: `booking-itinerary`,
                                        //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                                        //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                                        //     };
                                    
                                        //     addDataNotifications(idItinerary, data, false, false);

                                        // } catch (error) {
                                        //     console.error("Error al crear la notificación:", error);
                                        // }

                                    }else{
                                        deleteDataNotifications(idItinerary);
                                    }

                                }
                            }
                        }

                        if (created_date <= todayDate) {
                            // Obtener los valores del objeto bookingData
                            let total = bookingData?.total || 0; // Si no está definido, asumimos 0
                            let amountPaid = bookingData?.amountPaid || 0; // Si no está definido, asumimos 0

                            // Verificar si el total no es cero para evitar divisiones por cero
                            if (total > 0) {
                                const percentagePaid = (amountPaid / total) * 100;
                                let idDeposit = bookingData?.id + "-booking-no-deposit";

                                if(parseFloat(percentagePaid) < 50){
                                    ///////////////callback({ ...bookingData, type: "booking-no-deposit" });
                                    const truncateText = (text) =>
                                        text?.length > 20 ? `${text.slice(0, 20)}...` : text;
                                      
                                    const clientNameData = truncateText(
                                        bookingData?.clientName || bookingData?.newClientData
                                    );

                                    let data = {
                                        idService: bookingData?.id,
                                        title: `Deposit due`,
                                        message: `${clientNameData} unpaid deposit.`,
                                        type: `booking-no-deposit`,
                                        postponed: false,
                                        postponed_date: ``,
                                    }
                                    addDataNotifications(idDeposit, data, false, false);


                                    // try {
                                    //     const currentData = await fetchCurrentNotificationData(idDeposit);
                                
                                    //     let data = {
                                    //         idService: bookingData?.id,
                                    //         title: `Deposit due`,
                                    //         message: `${clientNameData} unpaid deposit.`,
                                    //         type: `booking-no-deposit`,
                                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                                    //     };
                                
                                    //     addDataNotifications(idDeposit, data, false, false);
                                    // } catch (error) {
                                    //     console.error("Error al crear la notificación:", error);
                                    // }

                                }else{
                                    deleteDataNotifications(idDeposit);
                                }
                            }
                        }
                    }
                    
                // }
            });
            
        });

        //Listener para adventures
        const unsubscribeAdventures = onSnapshot(adventuresCollection, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                // if (change.type === "added") {
                    const adventureData = change.doc.data();

                    // Obtenemos la fecha actual y la fecha de una semana en el futuro
                    const currentDate = new Date();
                    const futureDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 días en milisegundos
                    const pastDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 días en milisegundos
                    const todayDate = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 días en milisegundos

                    // Le damos un formato Date a la fecha de inicio (startDate)
                    const startDate = new Date(adventureData?.startDate); // Suponemos que startDate es una fecha en el formato correcto
                    // Convertir created_date desde un timestamp de Firebase a un objeto Date
                    const created_date = adventureData?.created_date ? new Date(adventureData.created_date.seconds * 1000) : new Date(0);
 
                    //Verifica que la fecha no sea mayor a un mes(30 dias) anterior
                    if (startDate >= pastDate) {
                        //Verifica que la fecha sea una semana a futuro del inicio de la actividad
                        if (startDate <= futureDate) {
                            // Si la fecha de inicio está dentro de una semana, entonces verificamos el balance
                            if (adventureData.balance > 0) {
                                ////////////////////callback({ ...adventureData, type: "adventure" });
                                const truncateText = (text) =>
                                    text?.length > 20 ? `${text.slice(0, 20)}...` : text;
                                  
                                const clientNameData = truncateText(
                                adventureData?.clientName || adventureData?.newClientData
                                );
                                
                                let idBooking = adventureData?.id + "-adventure";

                                let data = {
                                    idService: adventureData?.id,
                                    title: `Balance due`,
                                    message: `${clientNameData} is owing $${adventureData?.balance}`,
                                    type: `adventure`,
                                    postponed: false,
                                    postponed_date: ``,
                                }
                                addDataNotifications(idBooking, data, false, false);

                                // try {
                                //     const currentData = await fetchCurrentNotificationData(idBooking);
                            
                                //     let data = {
                                //         idService: adventureData?.id,
                                //         title: `Balance due`,
                                //         message: `${clientNameData} is owing $${adventureData?.balance}`,
                                //         type: `adventure`,
                                //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                                //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                                //     };
                            
                                //     addDataNotifications(idBooking, data, false, false);
                                // } catch (error) {
                                //     console.error("Error al crear la notificación:", error);
                                // }
                            }
                        }

                        if (created_date <= todayDate) {

                            // Obtener los valores del objeto adventureData
                            let total = adventureData?.total || 0; // Si no está definido, asumimos 0
                            let amountPaid = adventureData?.amountPaid || 0; // Si no está definido, asumimos 0
                        
                            // Verificar si el total no es cero para evitar divisiones por cero
                            if (total > 0) {
                                const percentagePaid = (amountPaid / total) * 100;
                                let idDeposit = adventureData?.id + "-adventure-no-deposit";

                                if(parseFloat(percentagePaid) < 50){
                                    /////////////////callback({ ...adventureData, type: "adventure-no-deposit" });
 
                                    const truncateText = (text) =>
                                        text?.length > 20 ? `${text.slice(0, 20)}...` : text;
                                      
                                    const clientNameData = truncateText(
                                    adventureData?.clientName || adventureData?.newClientData
                                    );

                                    let data = {
                                        idService: adventureData?.id,
                                        title: `Deposit due`,
                                        message: `${clientNameData} unpaid deposit.`,
                                        type: `adventure-no-deposit`,
                                        postponed: false,
                                        postponed_date: ``,
                                    }
                                    addDataNotifications(idDeposit, data, false, false);

                                    // try {
                                    //     const currentData = await fetchCurrentNotificationData(idDeposit);
                                
                                    //     let data = {
                                    //         idService: adventureData?.id,
                                    //         title: `Deposit due`,
                                    //         message: `${clientNameData} unpaid deposit.`,
                                    //         type: `adventure-no-deposit`,
                                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                                    //     };
                                
                                    //     addDataNotifications(idDeposit, data, false, false);
                                    // } catch (error) {
                                    //     console.error("Error al crear la notificación:", error);
                                    // }

                                }else{
                                    deleteDataNotifications(idDeposit);
                                }
                            }

                        }
                    }
                // }
            });
        });

        // Listener para rentals
        // const unsubscribeRentals = onSnapshot(rentalsCollection, (snapshot) => {
        //     snapshot.docChanges().forEach((change) => {
        //         if (change.type === "added") {
        //             const rentalData = change.doc.data();
        //             if (rentalData.balance > 0) {
        //                 callback({ ...rentalData, type: "rental" });
        //                 // toast.warning(`New rental added with a balance of $${rentalData.balance}`);
        //             }
        //         }
        //     });
        // });

        // Retornar una función que limpie todos los listeners al mismo tiempo
        return () => {
            unsubscribeBookings();
            unsubscribeAdventures();
            // unsubscribeRentals();
        };
    }
};

const fetchCurrentNotificationData = async (idDeposit) => {
    try {
        // Obtener datos existentes desde la base de datos
        const existingData = await getOneNotificationData(idDeposit); // Implementa esta función para acceder a tu base de datos.
        return existingData || {};
    } catch (error) {
        console.error("Error al obtener los datos existentes:", error);
        return {};
    }
};

