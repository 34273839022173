import jsPDF from 'jspdf';
import imgw from "../../../assets/Word_Logo_Color_Cream_compress_2.png";
import imgWater from "../../../assets/White_water_rafting.jpg";
import imgVan from "../../../assets/Van_13.jpg";
import imgTrees from "../../../assets/Trees.jpg";
import imgMountain1 from "../../../assets/Montains1.jpg";
import imgMountain2 from "../../../assets/Montains2.jpg";

import instagram from "../../../assets/Instagram.png";
import facebook from "../../../assets/Facebook.png";


async function transConfirmationPDF(data) {
  const doc = new jsPDF();
  const compressedImgw = await compressImage(imgw);
  const compressedImgWater = await compressImage(imgWater);
  const compressedImgVan = await compressImage(imgVan);
  const compressedImgTrees = await compressImage(imgTrees);
  const compressedImgMount1 = await compressImage(imgMountain1);
  const compressedImgMount2 = await compressImage(imgMountain2);
  // const compressedImgInstagram = await compressImage(instagram);
  // const compressedImgFacebook = await compressImage(facebook);

  /////////////////*****************************/////////////////
  // Obtener las dimensiones de la imagen
  const imgWidthTree = 220; // Ancho de la imagen en unidades PDF
  const imgHeightTree = 170; // Alto de la imagen en unidades PDF

  // Obtener las dimensiones del PDF
  const pdfWidth = doc.internal.pageSize.width;
  const pdfHeight = doc.internal.pageSize.height;

  // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
  const x = (pdfWidth - imgWidthTree) / 2; // Centrar horizontalmente
  const y = pdfHeight - imgHeightTree; // Colocar en la parte inferior

  // Agregar la imagen al PDF
  doc.addImage(compressedImgTrees, 'JPEG', x, y, imgWidthTree, imgHeightTree);
  /////////////////*****************************/////////////////


  // Company image
  const imgWidth = 60;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 25;
  // const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  const imgMarginLeft = 20;
  // doc.addImage(compressedImgTrees, 'JPG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
  doc.addImage(compressedImgw, "JPG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Agregar el texto proporcionado al PDF
  doc.setFontSize(12);
  doc.text(`Hi ${data?.clientName ? data?.clientName : (data?.client?.label ? data?.client?.label : "dear client")},`, 20, 50);
  doc.text("Thank you for booking with Tunnel49!", 20, 60);

  // Texto en negrita
  doc.setFont('helvetica', 'bold');
  doc.text("Please review the following information to ensure the accuracy of your reservation.", 20, 70);
  // Restaurar la fuente normal
  doc.setFont('helvetica', 'normal');

  doc.text(`YYC Airport Shuttle - ${ data?.nop } ` , 30, 80);  
  doc.text(`Pick-up Location: ${ data?.pickUp?.label == "YYC" ? "Door 12, Departures Level, YYC Calgary International Airport": data?.pickUp.label ? data?.pickUp.label : "-" }`, 30, 90);
  doc.text(`Drop-off Location: ${ data?.dropOff.label ? data?.dropOff.label : "-" }`, 30, 100);
  doc.text(`Starts: ${ data?.startDate ? data?.startDate : "-" } at ${ data?.startTime }`, 30, 110);
  doc.text(`Invoice Total: ${ data?.total }`, 30, 120);
  doc.text(`Balance Due: ${ data?.balance }`, 30, 130);

  // Texto en negrita
  doc.setFont('helvetica', 'bold');
  doc.text("Please note, we begin our Fernie Pick-ups at 7:30 AM to make sure we will be ready to ", 20, 140);
  doc.text("depart Fernie at 8:00 AM. We will notify you if we need to collect you before 7:30 AM.", 20, 150);
  // Restaurar la fuente normal
  doc.setFont('helvetica', 'normal');
  doc.text("If you have any questions at all, please don't hesitate to call our office at 250-423-5008.", 20, 160);
  doc.text("We look forward to seeing you soon!", 20, 170);

  // // Línea separadora
  // doc.setLineWidth(0.5); // Establecer el grosor de la línea
  // doc.line(20, 35, 190, 150); // Dibujar una línea horizontal

  doc.text("The shuttle will depart Calgary YYC at 01:00 PM and Fernie at 08:00 AM, please notify staff", 20, 190);
  doc.text("of your drop-off and pick-up Location if you haven’t already.", 20, 200);
  doc.text("Please aim to be ready one (1) hour before the set departure time from Fernie or YYC.", 20, 220);
  doc.text("The collection of guests starts at 07:00 AM on busy days from Fernie.  A means of contacting", 20, 230);
  doc.text("guests is needed to coordinate your pick-up time.", 20, 240);


  // Verificar si la altura del contenido excede el límite de una sola página
  if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
  } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    /////////////////*****************************/////////////////
    // Obtener las dimensiones de la imagen
    const imgWidthM = 220; // Ancho de la imagen en unidades PDF
    const imgHeightM = 120; // Alto de la imagen en unidades PDF

    // Obtener las dimensiones del PDF
    const pdfWidth = doc.internal.pageSize.width;
    const pdfHeight = doc.internal.pageSize.height - 10;

    // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
    const x = (pdfWidth - imgWidthM) / 2; // Centrar horizontalmente
    const y = pdfHeight - imgHeightM; // Colocar en la parte inferior

    // Agregar la imagen al PDF
    doc.addImage(compressedImgMount1, 'JPEG', x, y, imgWidthM, imgHeightM);

    //////////////********************************/////////////////

    doc.setFont('helvetica', 'bold');
    doc.text("Terms of service:", 20, 20);
    doc.setFont('helvetica', 'normal');

    // doc.text("Cancellation", 20, 240);
    // Texto subrayado
    const textWidthC = doc.getTextWidth("Cancellation");
    const textXC = 20;
    const textYC = 36;

    // Guardar el estado actual de la fuente
    const font = doc.internal.getFont();

    // Establecer la fuente en cursiva solo para el texto "Cancellation"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Cancellation", textXC, textYC);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXC, textYC + 2, textXC + textWidthC, textYC + 2); // Dibujar una línea debajo del texto

      
    doc.text("Reservations are non-refundable if canceled within 72 hours of the scheduled pick-up", 20, 44);
    doc.text("date/time. Cancellations received outside of that 72 hour window will receive a full refund ", 20, 52);
    doc.text("minus a $50 + GST administrative fee.", 20, 60);
    doc.text("Cancellations due to flight changes can be rebooked at a $50.00 charge.", 20, 68);

    // Contenido a la segunda página
    // doc.text("Missing Flight", 20, 300);
    // Texto subrayado
    const textWidthM = doc.getTextWidth("Missing Flight");
    const textXM = 20;
    const textYM = 84;

    // Establecer la fuente en cursiva solo para el texto "Missing Flight"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Missing Flight", textXM, textYM);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXM, textYM + 2, textXM + textWidthM, textYM + 2); // Dibujar una línea debajo del texto

    doc.text("Tunnel49 will not be responsible for delays causing missed flights that are the result of ", 20, 92);
    doc.text("inclement weather, unforeseen road closures, highway construction, abnormally heavy traffic or", 20, 100);
    doc.text("any other conditions outside of its control. Please be aware that weather, traffic, or unforeseen", 20, 108);
    doc.text("road closures can severely impact mountain travel times during holidays and peak travel days." , 20, 116);
    doc.text("Availability may be limited for last-minute changes. We encourage guests to plan accordingly ", 20, 124);
    doc.text("and purchase travel insurance to cover the potential for  missed flights and resulting expenses.", 20, 132);


    const textWidthP = doc.getTextWidth("Pets");
    const textXP = 20;
    const textYP = 148;

    // Establecer la fuente en cursiva solo para el texto "Pets"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Pets", textXP, textYP);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);

    // doc.line(textXP, textYP + 2, textXP + textWidthP, textYP + 2); // Dibujar una línea debajo del texto

    doc.text("Service animals are allowed.", 20, 156);
    doc.text("Pets are allowed only in a crate that needs to be provided by the passengers. Please notify ", 20, 164);
    doc.text("staff upon booking if you will be traveling with a service animal.", 20, 172);

    const textWidthI = doc.getTextWidth("Inbound flight delay");
    const textXI = 20;
    const textYI = 188;

    // Establecer la fuente en cursiva solo para el texto "Inbound flight delay"
    doc.setFont(font.fontName, 'bolditalic');
    doc.text("Inbound flight delay", textXI, textYI);

    // Restaurar el estado de la fuente
    doc.setFont(font.fontName, font.fontStyle);
    // doc.line(textXI, textYI + 2, textXI + textWidthI, textYI + 2); // Dibujar una línea debajo del texto

    doc.text("In the event of a flight delay, the shuttle will wait for 15 minutes from the initially scheduled time", 20, 196);
    doc.text("provided at booking. Drivers will not wait for delayed luggage.", 20, 204);
    doc.text("Parties delayed 15 minutes from the requested pick-up time, will be billed at $99/hour ", 20, 212);
    doc.text("plus 5% GST in 15-minute increments.", 20, 220);

    // (footer)
    doc.setFillColor(23, 61, 56);
    doc.rect(
      0,
      doc.internal.pageSize.height - 10,
      doc.internal.pageSize.width,
      20,
      "F"
    );

    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
      // Si el contenido cabe en una sola página, devolver el PDF
      return doc.output('datauristring');
    } else {
      // Si el contenido excede el límite de una sola página, agregar una nueva página
      doc.addPage();

      /////////////////*****************************/////////////////
      // Obtener las dimensiones de la imagen
      const imgWidthM2 = 220; // Ancho de la imagen en unidades PDF
      const imgHeightM2 = 120; // Alto de la imagen en unidades PDF

      // Obtener las dimensiones del PDF
      const pdfWidth = doc.internal.pageSize.width;
      const pdfHeight = doc.internal.pageSize.height - 10;

      // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
      const x = (pdfWidth - imgWidthM2) / 2; // Centrar horizontalmente
      const y = pdfHeight - imgHeightM2; // Colocar en la parte inferior

      // Agregar la imagen al PDF
      doc.addImage(compressedImgMount2, 'JPEG', x, y, imgWidthM2, imgHeightM2);

      //////////////********************************/////////////////
      

      const textWidthL = doc.getTextWidth("Luggage Allowance");
      const textXL = 20;
      const textYL = 20;

      // Establecer la fuente en cursiva solo para el texto "Luggage Allowance"
      doc.setFont(font.fontName, 'bolditalic');
      doc.text("Luggage Allowance", textXL, textYL);

      // Restaurar el estado de la fuente
      doc.setFont(font.fontName, font.fontStyle);
      // doc.line(textXL, textYL + 2, textXL + textWidthL, textYL + 2); // Dibujar una línea debajo del texto

      doc.text("Passengers are allowed two pieces of luggage plus a carry-on. All skis and snowboards must", 20, 30);
      doc.text("be properly packaged for transport. Additional luggage charges ($20/piece) will apply for guests", 20, 38);
      doc.text("traveling with more luggage than the shuttle allowance. Please inform us beforehand of any", 20, 46);
      doc.text("oversized luggage (or special luggage such as musical instruments). Any oversized luggage ", 20, 54);
      doc.text("or big sports bags will be charged at $20 +GST.", 20, 62);
      doc.text("No smoking in our vehicles, a $200 minimum fee will apply to anyone smoking or vaping.", 20, 70);


      doc.text("If you have any questions, don't hesitate to get in touch with us!", 20, 86);
      doc.text("Thank you for choosing Tunnel49", 20, 94);
      // doc.text("www.t49.ca", 20, 120);
      doc.setTextColor(222, 168, 69); // Establecer color amarillo
      doc.textWithLink("www.t49.ca", 20, 102, { url: "https://www.t49.ca" });
      doc.setTextColor("normal"); // Establecer color normal

      doc.text("(250) 423 - 5008", 20, 110);

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(16); // Aumenta el tamaño del texto a 16
      doc.text("More from Tunnel49:", 20, 125);
      doc.setFont('helvetica', 'normal');

      // White water rafting image
      const imgWidthWater = 90;
      const imgOriginalWidthWater = 500;
      const imgOriginalHeightWater = 307;
      const imgHeight = (imgWidthWater * imgOriginalHeightWater) / imgOriginalWidthWater;
      const imgMarginTopWater = 135;
      // const imgMarginLeft = (doc.internal.pageSize.width - imgWidthWater) / 2;
      const imgMarginLeftWater = 15;
      doc.addImage(compressedImgWater, "JPG", imgMarginLeftWater, imgMarginTopWater, imgWidthWater, imgHeight);

      doc.setFontSize(10); // El tamaño del texto a 10

      doc.text("Experience the ultimate river adventure today! Whether", 17, 197);
      doc.text("you crave the thrill of Class Four rapids or prefer a", 17, 202);
      doc.text("leisurely float down the Elk River with friends, we've", 17, 207);
      doc.text("got you covered. Book paddleboard tours, white water", 17, 212);
      doc.text("rafting, floats, kayaking tours, or rent your own river", 17, 217);
      doc.text("equipment! Visit ", 17, 222);
      doc.setTextColor(222, 168, 69); // Establecer color amarillo
      doc.textWithLink("t49.ca ", 44, 222, { url: "https://www.t49.ca" });
      doc.setTextColor("normal"); // Establecer color normal
      doc.text("to discover more.", 55, 222);


      // Van 13 image
      const imgWidthVan = 90;
      const imgOriginalWidthVan = 400;
      const imgOriginalHeightVan = 266;
      const imgHeightVan = ((imgWidthVan * imgOriginalHeightVan) / imgOriginalWidthVan) - 5;
      const imgMarginTopVan = 135;
      // const imgMarginLeft = (doc.internal.pageSize.width - imgWidthVan) / 2;
      const imgMarginLeftVan = 110;
      doc.addImage(compressedImgVan, "JPG", imgMarginLeftVan, imgMarginTopVan, imgWidthVan, imgHeightVan);

      doc.setFontSize(10); // El tamaño del texto a 10

      doc.text("Tunnel49 is the convenient and reliable service for your", 112, 197);
      doc.text("transportation needs! Offering a range of options from a", 112, 202);
      doc.text("14-seater van to a 56-seater coach, we provide flexibility", 112, 207);
      doc.text("for different group sizes and occasions. Providing", 112, 212);
      doc.text("transportation for events like weddings and sports", 112, 217);
      doc.text("games, we alleviate stress for both hosts and guests.", 112, 222);
      doc.text("Visit ", 112, 227);
      doc.setTextColor(222, 168, 69); // Establecer color amarillo
      doc.textWithLink("t49.ca/transportation-services/", 121, 227, { url: "https://t49.ca/transportation-services/" });
      doc.setTextColor("normal"); // Establecer color normal
      doc.text("to discover.", 170, 227);



      // (Footer)
      doc.setFillColor(23, 61, 56);
      doc.rect(
        0,
        doc.internal.pageSize.height - 10,
        doc.internal.pageSize.width,
        20,
        "F"
      );

    

    doc.setFillColor(23, 61, 56);
    doc.rect(0, doc.internal.pageSize.height - 10, doc.internal.pageSize.width, 20, "F");

    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255);
    const textFacebook = 'facebook.com/Tunnel49';
    const textInstragram = 'tunnel49adventures';
    const textWidthF = doc.getStringUnitWidth(textFacebook) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const textWidthI = doc.getStringUnitWidth(textInstragram) * doc.internal.getFontSize() / doc.internal.scaleFactor;

    const totalWidth = textWidthF + 10 + 20; // Ancho del icono
    const startX = (doc.internal.pageSize.width - totalWidth) / 2;

    // Agrega el texto al PDF
    doc.textWithLink(textFacebook, 65, doc.internal.pageSize.height - 5, { url: 'https://www.facebook.com/Tunnel49' });
    doc.textWithLink(textInstragram, 115, doc.internal.pageSize.height - 5, { url: 'https://www.instagram.com/tunnel49adventures' });

    // Agrega la imagen de Facebook al PDF con un enlace
    doc.addImage(facebook, 'PNG', 59, doc.internal.pageSize.height - 9, 5, 5);
    doc.link(59, doc.internal.pageSize.height - 9, 5, 5, { url: 'https://www.facebook.com/Tunnel49' });
    // Agrega la imagen de Instagram al PDF con un enlace
    doc.addImage(instagram, 'PNG', 109, doc.internal.pageSize.height - 9, 5, 5);
    doc.link(109, doc.internal.pageSize.height - 9, 5, 5, { url: 'https://www.instagram.com/tunnel49adventures' });

  }

    // Obtener los datos binarios del PDF como una cadena
    const pdfData = doc.output();

    // Codificar los datos binarios en base64
    const pdfBase64 = btoa(pdfData);

    // Devolver los datos codificados en base64
    return pdfBase64;
    
  }

}

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = 'white'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};


export default transConfirmationPDF;
