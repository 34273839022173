import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { auth, db } from '../firebase'
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { numtoDate, monthNames, dayOfWeekNames, formatDate, twoDigitPad } from './Tools';
import { server } from './serverName/Bookings';
import { ExcelRenderer } from 'react-excel-renderer';
import transConfirmationPDF from './exports/emailNotification/transConfirmationPDF';
import termsConditionsPDF from './exports/emailNotification/termsConditionsPDF';
import contractConfirmation from './exports/emailNotification/contractConfirmation';
import itineraryConfirmation from './exports/emailNotification/itineraryConfirmation';
import YXCConfirmationPDF from './exports/emailNotification/YXCConfirmationPDF';
import WWRConfirmationPDF from './exports/emailNotification/WWRConfirmationPDF';
import { sendEmail, sendEmailNotPDF } from './email/sendEmail';
import { sendEmailArrayPDF } from './email/sendEmailArrayPDF';
import noahsing from "../assets/Noah-sing.png";

export const getData = async (setdata, setPending = null) => {
    try {
      if (auth.currentUser != null) {
        const q = query(collection(db, server));
        const querySnapshot = await getDocs(q);
  
        setdata({ rows: [] });
  
        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
        setdata({
          rows: array
        });
  
        if (setPending) {
          setPending(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
}

export const sendVerificationEmail = async (data, clientList, setSendEmailPending = null, emailType = null) => {

  const recipient = getClientEmail(data, clientList);
  const subject = "Booking confirmation";
  const text = generateEmailText(data);

  try {
    if(emailType === "YYC"){
      const pdfBytes = await transConfirmationPDF(data);
      sendEmail(recipient, subject, text, pdfBytes);
    }else if(emailType === "YXC"){
      const pdfBytes = await YXCConfirmationPDF(data);
      sendEmail(recipient, subject, text, pdfBytes);
    }
    
    // Aquí se puede mostrar un mensaje de confirmación al usuario

  } catch (error) {
    console.error('Failed to send email, transConfirmationPDF, sendEmail');
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
}

export const sendEmailMarketingService = async (element, setSendEmailPending = null) => {

  const recipient = element?.email ? element?.email : "joel@t49.ca"; /*AQUI VAN LOS EMAILS A LOS QUE SE LE ENVIARA UN CORREO*/
  const subject = "Your Next Adventure Awaits with Tunnel49!";
  const html = generateEmailTextMarketing(element);

  try {
    //const pdfBytes = await transConfirmationPDF(element);
    
    sendEmailNotPDF(recipient, subject, html);
    // console.log(recipient, subject, html);

    // Aquí se puede mostrar un mensaje de confirmación al usuario

  } catch (error) {
    console.error('Failed to send email, sendEmailNotPDF');
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
}

export const sendPrivateCharterEmail = async (data, clientList, setSendEmailPending = null, emailType = null) => {

  const recipient = getClientEmail(data, clientList);
  const subject = "Booking confirmation";
  const text = generateEmailText(data);

  try {
    // Generar los PDF
    const pdfBytesTerms = await termsConditionsPDF(data);
    const pdfBytesContract = await contractConfirmation(data);
    const pdfBytesItinerary = await itineraryConfirmation(data);

    // Llamar a sendEmail con un arreglo de archivos PDF
    sendEmailArrayPDF(recipient, subject, text, [pdfBytesItinerary, pdfBytesContract, pdfBytesTerms]);
    
    // Aquí se puede mostrar un mensaje de confirmación al usuario
  } catch (error) {
    console.error('Failed to send email, sendPrivateCharterEmail, sendEmail', error);
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
};


export const sendVerificationEmailWWR = async (data, clientList, setSendEmailPending = null, emailType = null) => {

  const recipient = getClientEmail(data, clientList);
  const subject = "Booking confirmation";
  const text = generateEmailText(data);

  try {
    const pdfBytes = await WWRConfirmationPDF(data);
    sendEmail(recipient, subject, text, pdfBytes);
    // Aquí se puede mostrar un mensaje de confirmación al usuario

  } catch (error) {
    console.error('Failed to send email:', error);
    // Aquí se puede mostrar un mensaje de error al usuario
  }
  if (setSendEmailPending) {
    setSendEmailPending(false);
  }
}

const getClientEmail = (data, clientList) => {
  const client = getClient(data, clientList);
  return client ? client.email : '';
}

const getClient = (data, clientList) => {
  return clientList.find(element => data?.client?.value === element?.value);
}

const generateEmailText = (data) => {
  const clientName = data?.clientName || (data?.client?.label || "client");
  return `
Dear ${clientName},
    
Thank you for booking with Tunnel49. 
    
We have attached your booking confirmation to this email. Please read over it carefully and let us know if you would like to make any adjustments to your reservation by replying to this email. 
Or, please feel free to call us at (250) 423-5008 if you prefer.


Tunnel49
Noah Kramer | Sales and Marketing 
info@t49.ca  www.t49.ca   (250) 423-5008

`;

}

const generateEmailTextMarketing = (data) => {
  const clientName = data?.client || "client";

  // Función para generar secciones repetitivas
  const generateSection = (title, points, img, linkText, linkHref) => {
    const pointList = points.map(point => `<li>${point}</li>`).join("");
    return `
      <p><strong>${title}:</strong></p>
      <ul>${pointList}</ul>
      <img style="width:18%; padding-left: 40px;" src="${img}"/>
      <p>📅 Book now here:<br>
        👉 <a href="${linkHref}">${linkText}</a></p>
    `;
  };

  const transfersSection = generateSection(
    "Transfers from YYC Airport",
    [
      "Punctual transfers to and from YYC Airport.",
      "Comfortable and modern vehicles.",
      "Professional and friendly drivers that guarantee you a relaxed experience."
    ],
    "https://firebasestorage.googleapis.com/v0/b/booking-t49.appspot.com/o/images%2FVan.jpeg?alt=media&token=1689a8cd-0943-4040-9f53-281118407b27",
    "YYC Airport",
    "https://t49.ca/calgary-airport-shuttle/"
  );

  const privateShuttlesSection = generateSection(
    "Private Shuttles",
    [
      "Customize your itinerary according to your preferences.",
      "Explore unique destinations at your own pace.",
      "Exclusive vehicles for you and your group.",
      "Experienced local drivers that guarantee a safe and enjoyable trip."
    ],
    "https://firebasestorage.googleapis.com/v0/b/booking-t49.appspot.com/o/images%2FBlue-bus.jpg?alt=media&token=b1a12198-330a-4a99-ac35-bf0896a0895b",
    "Private Shuttles",
    "https://t49.ca/private-charters/"
  );

  const raftingSection = generateSection(
    "White Water Rafting",
    [
      "Pure emotion: Navigate exciting rapids and feel the force of nature.",
      "Guaranteed safety: High quality equipment and certified guides for a safe experience.",
      "Suitable for everyone: From beginners to experts, we have the perfect tour for you."
    ],
    "https://firebasestorage.googleapis.com/v0/b/booking-t49.appspot.com/o/images%2Frafting.jpg?alt=media&token=67522f0d-d796-4932-9d21-a036c698f904",
    "White Water Rafting",
    "https://t49.ca/white-water-rafting/" // Sin enlace adicional en este caso
  );

  // HTML final
  return `
    <p>Hi ${clientName},</p>
    
    <p>Ready for an unforgettable experience full of adrenaline and fun? At Tunnel49, we have everything ready for you to live the adventure of a lifetime.</p>

    <p>🎒 <strong>Our services include:</strong></p>

    ${transfersSection}
    ${privateShuttlesSection}
    ${raftingSection}


    <br>
    <p>Don't miss this opportunity to explore nature like never before. Places are limited, secure yours today!</p>

    <p>If you have any questions or need more information, we are here to help. Reply to this email or contact us at <a href="tel:+12504235008">(250) 423-5008</a>.</p>

    <p>We would love to be part of your next adventure!</p>
    
    <br>
    <br>
    <!-- Imagen -->
    <img src="https://firebasestorage.googleapis.com/v0/b/booking-t49.appspot.com/o/images%2FLogo_sign.png?alt=media&token=4063f86c-fb4f-43ea-afce-accf6bb28871" alt="Noah Sing" />
    <p>Noah Kramer | Sales and Marketing</p>
    <p>📩 info@t49.ca | 🌐 <a href="https://www.t49.ca">www.t49.ca</a> | 📞 <a href="tel:+12504235008">(250) 423-5008</a></p>
  `;
};



// export const fileHandler = (event, setData) => {
//   let fileObj = event.target.files[0];
//   //just pass the fileObj as parameter
//   ExcelRenderer(fileObj, async (err, resp) => {
//     var array = [];
//     if (err) {
//       console.log(err);
//     }
//     else {
//       resp.rows.forEach(async (element, i) => {
//         if (i !== 0 && element[0]) {
//           var newObject = {
//             client: element[0] ? element[0] : '',
//             organisation: element[1] ? element[1] : '',
//             email: element[2] ? element[2] : '',
//             source: element[3] ? element[3] : '',
//             phone: element[4] ? element[4] : '',
//             status: element[5] ? element[5] : '',
//             owner: element[6] ? element[6] : '',
//             date: element[7] ? formatDate(numtoDate(element[7]), 'yyyy-MM-dd') : '',
//             modified: element[8] ? formatDate(numtoDate(element[8]), 'yyyy-MM-dd') : '',
//             nop: element[9] ? element[9] : '',
//             requested: element[10] ? formatDate(numtoDate(element[10]), 'yyyy-MM-dd') : '',
//             return_travel: element[11] ? formatDate(numtoDate(element[11]), 'yyyy-MM-dd') : '',
//             request_type: element[12] ? element[12] : '',
//             vehicle: element[13] ? element[13] : '',
//             value: element[14] ? element[14] : '',
//             booked: element[15] ? element[15] : '',
//             feedback: element[16] ? element[16] : '',
//           }
//           await addData(newObject);
//           array.push(newObject);
//         }
//       });
//       setData({ rows: array });
//     }

//     Swal.fire({
//       icon: "success",
//       title: "Imported",
//       text: "The clients was created successfully",
//       confirmButtonColor: '#173d38',
//       confirmButtonText: "Ok",
//     });
//   });
// }