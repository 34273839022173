import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import '../../../style/formClient.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDataAdventure } from '../../../services/adventureSettings/adventures';
import { getDataTypeOfTaxes } from '../../../services/dynamicList/typesOfTaxes';
import { getDataExtraCost } from '../../../services/adventureSettings/extraCost';
import { getDataDietary } from '../../../services/adventureSettings/dietary';
import { getInvoiceByIdAdventure } from '../../../services/invoiceAdventure';
import { getDataDiscounts } from '../../../services/dynamicList/discounts';
import { getDataAgents } from '../../../services/dynamicList/agents';
// import { getData } from "../../../services/crm/clients";
import { sendVerificationEmailWWR } from '../../../services/Services';
import { AiOutlineDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { addData, getData, deleteData, editData } from "../../../services/crm/clients";
import { BiMailSend } from "react-icons/bi";
import ModalC from '../../modal';
import FormClient from '../../clients/formClient';
import { UserAuth } from '../../../context/AuthContext';
import { getDataOneUser } from '../../../services/auth/users';
import { roleVisit } from '../../../services/Tools';
import { paymentTypes } from '../../../services/Tools';
import Loading from '../../../components/loading';

function FormConfig({ title, action, lastData, setModal, clients, setDuplicate = null}) {

    const [id, setId] = useState("");
    const [adventure, setAdventure] = useState("");
    const [modalClient, setModalClient] = useState(false);
    const [titleModalClient, setTitleModalClient] = useState('');

    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [adventureListAux, setAdventureListAux] = useState([]);
    const [adventureList, setAdventureList] = useState([{ value: '', label: '' }]);

    const [client, setClient] = useState({ value: "", label: "" });
    const [clientName, setClientName] = useState("");
    const [client_name, setClient_Name] = useState("");
    const [adultNumber, setAdultNumber] = useState(0);
    const [youthNumber, setYouthNumber] = useState(0);
    
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const [note, setNote] = useState("");
    const [paid, setPaid] = useState(false);
    const [taxes, setTaxes] = useState("");
    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState(0);
    const [waterFeeAmount, setWaterFeeAmount] = useState(0);
    const [dietary, setDietary] = useState("");
    const [amount, setAmount] = useState("");
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState("");
    const [tipAmount, setTipAmount] = useState(0);
    const [tipAmountPaid, setTipAmountPaid] = useState(0);
    const [amountPaid, setAmountPaid] = useState("");
    const [taxAmount, setTaxAmount] = useState("");
    const [total, setTotal] = useState("");
    const [balance, setBalance] = useState("");
    const [newClient, setNewClient] = useState(false);
    const [newClientData, setNewClientData] = useState("");
    // const [dataInvoice, setDataInvoice] = useState(null);
    const [bookingType, setBookingType] = useState("Directly");
    const [agents, setAgents] = useState([]);
    const [extraCost, setExtraCost] = useState([]);
    const [extraAmount, setExtraAmount] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [GST, setGST] = useState({ value: '5', label: 'GST' });
    const [invoicedVia, setInvoicedVia] = useState("");

    const [taxListAux, setTaxListAux] = useState([]);
    const [taxList, setTaxList] = useState([{ value: '', label: '' }]);

    const [discountListAux, setDiscountListAux] = useState([]);
    const [discountList, setDiscountList] = useState([{ value: '', label: '' }]);

    const [dietaryListAux, setDietaryListAux] = useState([]);
    const [dietaryList, setDietaryList] = useState([{ value: '', label: '' }]);

    const [clientListAux, setClientListAux] = useState([]);
    const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);

    const [agentsListAux, setAgentsListAux] = useState([]);
    const [agentsList, setAgentsList] = useState([{ value: '', label: '' }]);  

    const [extraCostListAux, setExtraCostListAux] = useState([]);
    const [extraCostList, setExtraCostList] = useState([{ value: '', label: '' }]);

    const { user, logOut } = UserAuth();
    const [userInfo, setUserInfo ] = useState('');
    const [sendEmail, setSendEmail] = useState(true);
    const [sendEmailPending, setSendEmailPending] = useState(false);

    const [cardSurcharge, setCardSurcharge] = useState(false);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0);
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);

    //Use by adventure, type of tax, dietary and client List
    useEffect(() => {
    if (user != null) {

        getDataAdventure(setAdventureListAux);
        getDataExtraCost(setExtraCostListAux);
        getDataDiscounts(setDiscountListAux);
        getDataTypeOfTaxes(setTaxListAux);
        getDataDietary(setDietaryListAux);
        getDataAgents(setAgentsListAux);
        
        if(clients?.rows?.length > 0){
            setClientListAux(clients);
        }else{
            getData(setClientListAux);
        }

        const getUserData = async () => {
            const userDocSnap = await getDataOneUser(user.uid);
            if(userDocSnap?.length > 0){
            // if(userDocSnap[0?.role !== roleVisit]){
                setUserInfo(userDocSnap[0]);
            // }
            // else{
            //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
            //   window.location.href = '/';
            // }
            }
        };
    
        getUserData();
    }

    }, []);

    //Use by adventure List
    useEffect(() => {
        if (adventureListAux?.rows?.length > 0) {
            var aux = [];
            adventureListAux?.rows?.forEach(element => {
                aux.push({ internalId: element?.internalId ? element?.internalId : "-", value: element?.color, label: element?.adventure, 
                adultRate: element?.adultRate, youthRate: element?.youthRate, waterFee: element?.waterFee });
            });
            setAdventureList(aux);
        }
    }, [adventureListAux]);

    //Use by taxes
    useEffect(() => {
        if (taxListAux?.rows?.length > 0) {
            var aux = [];
            taxListAux?.rows?.forEach(element => {                

                if(element?.type === "GST"){
                    setGST({ value: element?.tax, label: element?.type } );
                }

                if(element?.type !== "Fuel Surcharge"){
                    aux.push({ value: element?.tax, label: element?.type });
                }

            });
            setTaxList(aux);
        }
    }, [taxListAux]);

    //Use by discount
    useEffect(() => {
        if (discountListAux?.rows?.length > 0) {
            var aux = [];
            discountListAux?.rows?.forEach(element => {                
                aux.push({ value: element?.discount, label: (element?.type + ( " " + element?.discount +"%")) });
            });
            setDiscountList(aux);
        }
    }, [discountListAux]);

     //Use by dietary restrictions List
     useEffect(() => {
        if (dietaryListAux?.rows?.length > 0) {
            var aux = [];
            dietaryListAux?.rows?.forEach(element => {
                aux.push({ value: element?.dietary, label: element?.dietary });
            });
            setDietaryList(aux);
        }
    }, [dietaryListAux]);

    //Use by client List
    useEffect(() => {
        if (clientListAux?.rows?.length > 0) {
            var aux = [];
            clientListAux?.rows?.forEach(element => {
                let orga = element?.organisation;
                if(orga){
                    aux.push({ value: element?.id, label: element?.client, email: element?.email, phone: element?.phone, organization: orga });
                }else{
                    aux.push({ value: element?.id, label: element?.client, email: element?.email, phone: element?.phone, organization : "-" });
                }
            });
            setClientList(aux);
        }
    }, [clientListAux]);

    //Use by agents
    useEffect(() => {
        if (agentsListAux?.rows?.length > 0) {
            var aux = [];
            agentsListAux?.rows?.forEach(element => {
                aux.push({ value: element?.id, label: element?.name, commission: element?.commission });
            });
            setAgentsList(aux);
        }
    }, [agentsListAux]);

    //Use by extra cost
    useEffect(() => {
        if (extraCostListAux?.rows?.length > 0) {
            var aux = [];
            extraCostListAux?.rows?.forEach(element => {
                aux.push({ value: element?.cost, label: element?.type, quantity: 1 });
            });
            setExtraCostList(aux);
        }
    }, [extraCostListAux]);

    useEffect(() => {
        if (lastData) {
            setId(lastData?.id);
            // setAdventure(lastData?.adventure);
            setAdventure({ internalId: lastData?.adventure?.internalId ? lastData?.adventure?.internalId : "-", value: lastData?.adventure?.value, label: lastData?.adventure?.label, adultRate: lastData?.adventure?.adultRate, youthRate: lastData?.adventure?.youthRate, waterFee: lastData?.adventure?.waterFee });
            setClient(lastData?.client);
            setClientName(lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData);
            setClient_Name(lastData?.client_name ? lastData?.client_name : (lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData));
            setInvoicedVia(lastData?.invoicedVia ? ({ value: lastData?.invoicedVia?.value, label: lastData?.invoicedVia?.label }) : ({ value: "", label: "" }));
            setAdultNumber(lastData?.adultNumber);
            setYouthNumber(lastData?.youthNumber);
            setStartDate(lastData?.startDate);
            setEndDate(lastData?.endDate);
            setStartTime(lastData?.startTime);
            setEndTime(lastData?.endTime);
            setNote(lastData?.note);
            setPaid(lastData?.paid);
            setRefund(lastData?.refund ? lastData?.refund : false);
            setRefundAmount(lastData?.refundAmount ? lastData?.refundAmount : 0);
            setTipAmount(lastData?.tipAmount ? lastData?.tipAmount : 0);
            setTipAmountPaid(lastData?.tipAmountPaid ? lastData?.tipAmountPaid : 0);
            setAmount(lastData?.amount ? lastData?.amount : 0);
            setAmountPaid(lastData?.amountPaid ? lastData?.amountPaid : 0);
            setTaxAmount(lastData?.taxAmount ? lastData?.taxAmount : 0);
            setExtraAmount(lastData?.extraAmount ? lastData?.extraAmount : 0);
            setTotal(lastData?.total ? lastData?.total : 0);
            setBalance(lastData?.balance ? lastData?.balance : 0);
            setTaxes({ value: lastData?.taxes?.value, label: lastData?.taxes?.label });
            setDiscount({ value: lastData?.discount?.value, label: lastData?.discount?.label });
            setDiscountAmount(lastData?.discountAmount);
            setWaterFeeAmount(lastData?.waterFeeAmount);
            setNewClient(lastData?.newClient);
            setNewClientData(lastData?.newClientData);
            setBookingType(lastData?.bookingType ? lastData?.bookingType : "Directly");
            setCardSurcharge(lastData?.cardSurcharge ? lastData?.cardSurcharge : false);
            setCardSurchargeAmount(lastData?.cardSurchargeAmount ? lastData?.cardSurchargeAmount : 0);
            setCardSurchargePercentage(lastData?.cardSurchargePercentage ? lastData?.cardSurchargePercentage : 2.4); 

            if(lastData?.agents?.value){
                setAgents({ value: lastData?.agents?.value, label: lastData?.agents?.label, commission: lastData?.agents?.commission });
            }

            if (Array.isArray(extraCost)) {
                let data = [...extraCost];
                //if(lastData?.extraCost?.length > 0){
                for (let i = 0; lastData?.extraCost?.length > i; i++) {
                    data[i] = lastData?.extraCost[i];
                }
                setExtraCost(data);
                //}
            } else {
                let data = [];
                data[0] = lastData?.extraCost[0];
                setExtraCost(data);
            }
            // getInvoiceByIdAdventure(lastData?.id, setDataInvoice);
        }
    }, [lastData])

    const calculateTotal = (value, tax, disco, tip, extra, card = cardSurcharge) => {

        if(tax !== ""){
            let cal = 0; //Calculo del impuesto
            let imp = 0; //Monto total del impuesto
            let tot = 0; //Total con impuesto
            let bal = 0; //Balance == total - monto pagado
            let paid = 0; // Monto pagado
            let dis = 0; //Monto del descuento que agreguen segun el porcentaje
            let fee = 0; //Se guarda el monto del waterFee
            let car = 0; //Se guarda el monto del card surcharge
            let impExtra = 0; //Impuesto extra para las cosas extras, sumatoria
            let ext = 0; // Monto extra, sumatoria

            if(lastData){
                bal = lastData?.balance ? lastData?.balance : 0;
                // paid = (lastData?.amountPaid >= 0 ? parseFloat(lastData?.amountPaid) : 0) - parseFloat(tip);                 
                paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;                   

            }

            if(adultNumber > 0 && parseFloat(value) !== 0){
                if(adventure){
                    if(adventure?.waterFee){
                        fee = fee + (1 * parseFloat(adultNumber));
                    }
                }
            }
         
            if(youthNumber > 0 && parseFloat(value) !== 0){
                if(adventure){
                    if(adventure?.waterFee){
                        fee = fee + (1 * parseFloat(youthNumber));
                    }
                }
            }
            //Metodo para obtener el impuesto de los extras (lista dinamica)
            if(extra?.length > 0){
            extra.forEach(element => {
                if(element !== ''){
                    if(tax !== ""){
                        impExtra = parseFloat(impExtra) + (parseFloat(parseFloat(element?.value) * parseFloat(element?.quantity)) * parseFloat(parseFloat(tax?.value) / 100));
                    }
                    ext = parseFloat(ext) + ( parseFloat(element?.value) * parseFloat(element.quantity));
                }
            });
            }

            if(parseFloat(value) >= 0){
                setAmount(parseFloat(value));

                if(card){
                    car = parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
                }

                if(disco?.value && parseFloat(value) !== 0){
                    dis = (parseFloat(value) + parseFloat(fee)) * (parseFloat(disco?.value) / 100);
                }

                // cal = parseFloat(value) * (parseFloat(tax?.value) / 100);
                // cal = ((parseFloat(value) + parseFloat(fee) + parseFloat(car)) - parseFloat(dis)) * (parseFloat(tax?.value) / 100); 
                cal = ((parseFloat(value) + parseFloat(fee)) - parseFloat(dis)) * (parseFloat(tax?.value) / 100); 
                imp = parseFloat(cal) + parseFloat(impExtra);
                tot = parseFloat(value) + parseFloat(imp) + parseFloat(fee) + parseFloat(car) + parseFloat(ext) - parseFloat(dis);
                bal = parseFloat(tot) - (parseFloat(paid) - parseFloat(tip));

            }else{
                setAmount("");
            }
            
            setCardSurchargeAmount(parseFloat(car.toFixed(2)));
            setDiscountAmount(parseFloat(dis.toFixed(2)));
            setTaxAmount(parseFloat(imp.toFixed(2)));
            setWaterFeeAmount(parseFloat(fee.toFixed(2)));        
            setExtraAmount(parseFloat(ext.toFixed(2)));
            setTotal(parseFloat(tot.toFixed(2)));
            setAmountPaid(parseFloat(paid.toFixed(2)));
            setBalance(parseFloat(bal.toFixed(2)));

        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    const automaticTotal = (adult, youth, tax, adven, disco, extra = null, tip, card = cardSurcharge) => {
        
        setAdultNumber(adult ? parseFloat(adult) : 0);
        setYouthNumber(youth ? parseFloat(youth) : 0);

        let amo = amount ? amount : 0; //Monto sin impuestos ni descuento
        let cal = 0; //Calculo del impuesto
        let imp = 0; //Captura el impuesto segun *cal*
        let tot = 0; //total con los impuestos y descuento
        let bal = 0; //Balance = total - paid
        let paid = 0; //El monto pagado previamente
        let dis = 0; //El descuento que agreguen
        let fee = 0; //Se guarda el monto del waterFee
        let impExtra = 0; //Impuesto extra para las cosas extras, sumatoria
        let ext = 0; // Monto extra, sumatoria
        let car = 0; //Se guarda el monto del card surcharge

        if(lastData){
            bal = lastData?.balance ? lastData?.balance : 0;
            paid = lastData?.amountPaid >= 0 ? parseFloat(lastData?.amountPaid) : 0;                 
        }

        if(adult > 0){
            if(adven){
                if(adven?.adultRate){
                    amo = parseFloat(adven?.adultRate) * parseFloat(adult);
                }
                if(adven?.waterFee){
                    fee = fee + (1 * parseFloat(adult));
                }
            }
        }
     
        if(youth > 0){
            if(adven?.youthRate){
                amo = amo + (parseFloat(adven?.youthRate) * parseFloat(youth));
            }
            if(adven?.waterFee){
                fee = fee + (1 * parseFloat(youth));
            }
        }
        
        if(amo > 0){
            if(disco?.value){
                dis = parseFloat(amo) * (parseFloat(disco?.value) / 100);
            }

            if(card){
                // car = parseFloat(amo) > 0 ? parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100) : parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
                car = parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100);
            }

            if(tax !== ""){
                // cal = ((parseFloat(amo) - parseFloat(dis)) + parseFloat(fee) + parseFloat(car)) * (parseFloat(tax?.value) / 100); 
                cal = ((parseFloat(amo) - parseFloat(dis)) + parseFloat(fee)) * (parseFloat(tax?.value) / 100); 
            }
        }

        //Metodo para obtener el impuesto de los extras (lista dinamica)
        if(extra?.length > 0){
            extra.forEach(element => {
                if(element !== ''){
                    if(tax !== ""){
                        impExtra = parseFloat(impExtra) + (parseFloat(parseFloat(element?.value) * parseFloat(element?.quantity)) * parseFloat(parseFloat(tax?.value) / 100));
                    }
                    ext = parseFloat(ext) + ( parseFloat(element?.value) * parseFloat(element.quantity));
                }
            });
        }
        // imp = parseFloat(cal); 
        imp = parseFloat(cal) + parseFloat(impExtra);
        tot = parseFloat(amo) + parseFloat(fee) + parseFloat(car) + parseFloat(ext) + parseFloat(imp) - parseFloat(dis); 
        bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);


        setCardSurchargeAmount(parseFloat(car.toFixed(2)));
        setDiscountAmount(parseFloat(dis.toFixed(2)));
        setAmount(parseFloat(amo.toFixed(2)));
        setExtraAmount(parseFloat(ext.toFixed(2)));
        setTaxAmount(parseFloat(imp.toFixed(2)));
        setWaterFeeAmount(parseFloat(fee.toFixed(2)));
        setTotal(parseFloat(tot.toFixed(2)));
        setAmountPaid(parseFloat(paid.toFixed(2)));
        setBalance(parseFloat(bal.toFixed(2)));

    }
    
    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const changeBookingType = (e) => {
        if(e === "Directly"){
            setAgents([]);
            setBookingType("Directly");
        }else{
            setBookingType("Agent");
        }
    }

    const changeAdventure = (e) => {
        setAdventure(e);
        //Hora fija para rafting
        if(e?.internalId){
            if(e?.internalId === "rafting1" || e?.internalId === "mammoth2"){
                setStartTime("09:00");
                setEndTime("15:00");
            }else{
                setStartTime("");
                setEndTime("");
            }
        }
    }

    const addInputFieldExtraCost = (e) => {
        
        if(taxes?.value !== ""){
            e.preventDefault();
            setExtraCost([...extraCost, ""]);
        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    const handleInputChangeExtraCost = (index,extra,e) => {
        let data = [...extraCost];
        data[index] = extra;

        if(data[index]){
            data[index].quantity = e ? e : quantity;
            setQuantity(quantity);
        }
        setExtraCost(data);
        automaticTotal(adultNumber,youthNumber,taxes,adventure,discount,data, tipAmount, cardSurcharge);
    }

    const removeFieldsExtraCost = (index) => {
        let data = [...extraCost];
        data.splice(index, 1);
        setExtraCost(data);
        automaticTotal(adultNumber,youthNumber,taxes,adventure,discount,data, tipAmount, cardSurcharge);
    }

    const checkData = (data) => {

        let success = true;
        let extraNull = false;

        clientListAux?.rows?.forEach(element => {
            if(element?.client === data?.client?.label){
                data['client'] = { value: element?.id, label: element?.client };
                setClient({ value: element?.id, label: element?.client });
                setClientName(element?.client);
                setClient_Name(element?.client);
            }

        });

        if (adventure !== '' && adultNumber !== '' && startDate !== '' && startTime !== '' 
            && taxes !== "" && amount !== '') {

            extraCost.forEach(element => {
                if(element === ''){
                    success = false;
                    extraNull = true;
                }
            });

            if(extraNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add an extra cost.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }

            if(bookingType === "Agent" && agents?.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It's necessary to choose the agent.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                }); 
                success = false;
            }    
            
            // if(balance < 0){
            //     Swal.fire({
            //         icon: "error",
            //         title: "Error!",
            //         text: 'Failed to save, balance cannot be negative',
            //         confirmButtonColor: '#173d38',
            //         confirmButtonText: "OK",
            //     });
            //     success = false;
            // }else{
            //     if(balance !== 0){
            //         data['paid'] = false;
            //     }
            // }

            if(!data?.newClient){
                if(data?.client?.value === ""){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Client information has not been added.",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to register the client (CRM Information).",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }

            if(success){
                if(balance < 0){
                    Swal.fire({
                        title: 'Negative Balance',
                        text: 'Are you sure about this action?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                      }).then((result) => {
                        if (result.isConfirmed) {
                            data['refund'] = (parseFloat(data?.balance) + parseFloat(data?.amountPaid)) == 0 ? true : false;
                            data['refundAmount'] = parseFloat(balance);
                            data['paid'] = false;
                            action(data, lastData);
                        }
                      });
                }else{
                    if(balance === 0){
                        data['paid'] = true;
                    }else{
                        data['paid'] = false;
                    }
                    action(data, lastData);
                }
            }

        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "All fields with (*) are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
                });
        }

    }

    const showClient = () => {
        Swal.fire({
            title: '<strong>Client information</strong>',
            icon: 'info',
            html: `                              
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th><small>Name</small></th>
                <th><small>Organization</small></th>
                <th><small>Email</small></th>
                <th><small>Phone</small></th>
              </tr>
            </thead>
            <tbody>
                ${getClient()}
            </tbody>
          </table>`,
            showCloseButton: true,
            confirmButtonColor: "#173d38",
            width: 700,
        })
        // <th><small>Action</small></th>
    }

    const getClient = () => {
        var tbody = "";

        if (clientList) {
            clientList.forEach(element => {
                if(element?.value === client?.value){
                    let orga = element?.organization;
                    let pho = element?.phone;
                    tbody = tbody + `<tr><td><small>${element?.label}</small></td><td style="max-width:100px"><small>${orga ? (orga) : ("-")}</small></td><td><small>${element?.email}</small></td><td><small>${ pho ? (pho) : ("-")}</small></td> </tr>`
                }
            });
        } else {
            tbody = "<tr><td colspan='6'>Without results</td></tr>";
        }
        return tbody;
    }

    const sendBookingConfirmation = () => {
        if(lastData?.adventure?.label == "Whitewater Rafting" || lastData?.adventure?.internalId == "rafting1") {
            setSendEmailPending(true);
            sendVerificationEmailWWR(lastData, clientList, setSendEmailPending, "Whitewater Rafting");        
            setSendEmail(false);
        }
    }

    const openClientModal = () => {
        setModalClient(true);
        setTitleModalClient('Add Client');
    };

    const actionClient = (data) => {
        // handleClearRows();
        data['owner'] = data['owner'] ? data['owner'].value : "";
        setNewClientData(data['client']);

        addData(data, true);
        setModalClient(false);

        getData(setClientListAux);
        setClient({ value: '', label: data['client'] });
        setClientName(data['client']);
        setClient_Name(data['client']);
        
        setNewClient(false);
        setNewClientData("");

    }

    return (

        <>
        <div>
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-4'>
                    
                    {/* <Form.Group className="mb-3" controlId="formFullName">
                        <div className="d-inline-flex">
                            <Form.Label>Client name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the client name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group> */}
                    { userInfo?.role !== roleVisit ? ( 

                    <Form.Group className="mb-3" controlId="formNoRegister">
                    <div className="d-inline-flex">
                        <Form.Label>Is the client registered?</Form.Label>
                        <h5 className="required">*</h5>
                    </div>
                    <div className="d-block">
                        <div style={{display: "-webkit-box"}}>
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={newClient === false}
                                onChange={() => { setNewClient(false); setNewClientData(""); }}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={newClient === true}
                                onChange={() => { setNewClient(true); setClient({ value: "", label: "" }); }}
                            />No
                            { newClient ? (
                                <div style={{marginLeft: "auto"}}>
                                    <Button className={'color-green rounded-circle'} onClick={openClientModal}><BiAddToQueue></BiAddToQueue></Button>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    </Form.Group>

                    ) : ""}

                    { !newClient ? (
                    <Form.Group className="mb-3" controlId="formClientName">
                        <div className="d-inline-flex">
                            { client && (client?.value !== "") && (userInfo?.role !== roleVisit) ? ( <Form.Label><FaInfoCircle className={'mb-1 color-green-info  p-0 rounded-circle'} onClick={showClient} data-toggle="tooltip" data-placement="top" title="Show the client information"></FaInfoCircle>Client name</Form.Label> )
                                : (<Form.Label>Client name</Form.Label>)
                            } 
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={clientList} 
                            value={client}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => {setClient(e); setClientName(e?.label); setClient_Name(e?.label); }} />

                    </Form.Group>
                    ) : (
                        <Form.Group className="mb-3" controlId="formNewClient">
                        <div className="d-inline-flex">
                            <Form.Label>Unregistered client name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the client name"
                            value={newClientData}
                            onChange={(e) => { setNewClientData(e.target.value); setClientName(e.target.value); setClient_Name(e.target.value); }}
                            onKeyDown={(e) => keypress(e)}
                        />

                        </Form.Group> 
                    )}
                    { userInfo && userInfo.role !== roleVisit ? (
                    <>
                        { lastData && (lastData?.adventure?.label == "Whitewater Rafting" || lastData?.adventure?.internalId == "rafting1") ? (
                            sendEmailPending ? (
                                <div className="d-inline-flex" style={{ maxWidth: "5px" }}>
                                    <Button style={{ background: "#173d38" }}> <Loading small={true}></Loading></Button>
                                </div>
                            ) : (
                                sendEmail ? (
                                    <Form.Group className="mb-3" controlId="formShuttle">
                                        <div className="d-inline-flex">
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Send confirmation</Tooltip>}
                                            >
                                            <Button style={{ background: "#173d38" }} onClick={() => sendBookingConfirmation()}><BiMailSend /></Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                ) : ("")
                            )
                        ) : ("")}
                    </>
                    ) : "" }  

                    <Form.Group className="mb-3" controlId="formAdventure">
                        <div className="d-inline-flex">
                            <Form.Label>Adventure</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select 
                            options={adventureList} 
                            value={adventure}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { changeAdventure(e); automaticTotal(adultNumber,youthNumber,taxes,e,discount,extraCost,tipAmount, cardSurcharge) }} />

                        {/* <Form.Control type="text" placeholder="Enter the adventure"
                            value={adventure}
                            onChange={(e) => setAdventure(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />     */}

                    </Form.Group>  

                    <Form.Group className="mb-3" controlId="formAdult">
                        <div className="d-inline-flex">
                            <div className="col-md-6">
                                <div className="d-inline-flex">
                                    <Form.Label>Number of adults</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                                
                                <Form.Control className='w-50' type="number" placeholder="0"
                                    value={adultNumber}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => automaticTotal(e.target.value,youthNumber,taxes,adventure,discount,extraCost,tipAmount, cardSurcharge)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>

                            <div className="d-inline-flex col-md-6">
                                <div className="col-md-12">
                                    <Form.Label>Number of youths</Form.Label>
                                    <Form.Control className='w-50' type="number" placeholder="0"
                                        value={youthNumber}
                                        readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => automaticTotal(adultNumber,e.target.value,taxes,adventure,discount,extraCost,tipAmount, cardSurcharge)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </div>
                        </div>

                       
                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formStartDate">
                        <div className="d-inline-flex">
                            <Form.Label>Start date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="date" placeholder="Enter the start date"
                            value={startDate}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setStartDate(e.target.value); setEndDate(e.target.value)}}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group> 

                    {/* <Form.Group className="mb-3" controlId="formEndDate">
                        <div className="d-inline-flex">
                            <Form.Label>End date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="date" placeholder="Enter the end date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>  */}

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formStartTime">
                                <div className="d-inline-flex">
                                    <Form.Label>Start Time</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="time" placeholder="9:00"
                                    value={startTime}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => setStartTime(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formEndTime">
                                <div className="d-inline-flex">
                                    <Form.Label>End Time</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="time" placeholder="15:00"
                                    value={endTime}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => setEndTime(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3">
                        <div className="retail-container">
                            <div className='flex d-flex justify-content-between mb-2'>
                                <div className="d-inline-flex">
                                    <Form.Label>Extra cost</Form.Label>
                                </div>
                                { userInfo?.role !== roleVisit ? (
                                    <Button className={'color-green rounded-circle'} onClick={addInputFieldExtraCost}><BiAddToQueue></BiAddToQueue></Button>
                                ) : "" }

                            </div>
                            <div className="retail-scroll-container">
                                {extraCost.map((extraCost, index) => (
                                    <div key={index} className="input-group mb-3">                                
                                        <Select 
                                            options={extraCostList} 
                                            className="form-control"
                                            id={`extra-${index + 1}`}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            menuPortalTarget={document.body}
                                            value={extraCost}
                                            onChange={(e) => handleInputChangeExtraCost(index, e,document.getElementById(`idExtra-${index}`).value)} />

                                        <Form.Control type="text" key={index} id={`idExtra-${index}`}
                                            placeholder={extraCost?.quantity}
                                            style={{maxWidth:"20%", textAlign:"center"}}
                                            onChange={(e) => handleInputChangeExtraCost(index,extraCost,e.target.value)}
                                            onKeyDown={(e) => keypress(e)}/> 
                                        
                                        { userInfo?.role !== roleVisit ? (
                                            <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} onClick={() => removeFieldsExtraCost(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                                        ) : "" }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Form.Group>
                </div>

                <div className='col-md-4'>

                    <Form.Group className="mb-3" controlId="formTaxRate">
                        <div className="d-inline-flex">
                            <Form.Label>Tax rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select options={taxList} value={taxes}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => {
                                setTaxes(e); automaticTotal(adultNumber,youthNumber,e,adventure,discount,extraCost,tipAmount, cardSurcharge);
                            }} />

                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formDiscount">
                        <div className="d-inline-flex">
                            <Form.Label>Discount</Form.Label>
                        </div>
                        <div className="d-flex">
                            <div style={discount?.value !== "" ? {width: "90%"} : {width: "100%"}}>
                                <Select options={discountList} value={discount}
                                    isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                                    defaultValue={discountList[0]}
                                    onChange={(e) => {
                                        setDiscount(e); automaticTotal(adultNumber,youthNumber,taxes,adventure,e,extraCost,tipAmount, cardSurcharge);
                                    }}
                                />
                            </div>

                            { discount?.value !== "" ? (
                                <div style={{width: "10%"}}>
                                    <Button 
                                        className={'input-group-text'} 
                                        style={{ marginLeft: 3, background: "#f4673c" }} 
                                        onClick={() => { 
                                        setDiscount({ value: "", label: "" }); 
                                        automaticTotal(adultNumber,youthNumber,taxes,adventure, { value: "", label: "" }, extraCost, tipAmount, cardSurcharge); }}>
                                        <AiOutlineDelete ></AiOutlineDelete>
                                    </Button> 
                                </div>
                            ) : (<></>)}
                        </div>
                    </Form.Group> 

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>Add Card Surcharge?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === true}
                                onChange={() => { 
                                    setCardSurcharge(true); 
                                    automaticTotal(adultNumber,youthNumber,taxes,adventure, discount, extraCost, tipAmount, true);
                                    setSendEmail(false);
                                }}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === false}
                                onChange={() => { 
                                    setCardSurcharge(false); 
                                    setCardSurchargeAmount(0); 
                                    automaticTotal(adultNumber,youthNumber,taxes,adventure, discount, extraCost, tipAmount, false); 
                                    setSendEmail(false);
                                }}
                            />No
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>                        
                                {cardSurcharge ? ("The Card Surcharge is " + cardSurchargePercentage +"%") : ("")}
                            </Form.Label>
                        </div>
                    </Form.Group>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formAmount">
                                <div className="d-inline-flex">
                                    <Form.Label>Amount</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number"
                                        value={amount}
                                        readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => calculateTotal(e.target.value,taxes, discount, tipAmount, extraCost, cardSurcharge)}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTip">
                                <div className="d-inline-flex">
                                    <Form.Label>Tip</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={tipAmount}
                                        readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => { setTipAmount(e.target.value); (amount ? calculateTotal(amount, taxes, discount, (e.target.value ? e.target.value : 0), extraCost, cardSurcharge) : automaticTotal(adultNumber,youthNumber,taxes,adventure,discount,extraCost, (e.target.value ? e.target.value : 0), cardSurcharge) )}}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group> 
                        </div>

                        
                    </div>

                    <div className='row'>
                        { extraAmount > 0 ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formExtraAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Extra amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(extraAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setExtraAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div>
                                </Form.Group> 
                            </div>
                        ) : ("")} 

                        { discountAmount ? (
                            <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formDiscountAmount">
                                <div className="d-inline-flex">
                                    <Form.Label>Discount amount</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(discountAmount).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setDiscountAmount(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group>
                            </div>
                        ) : ("") }
                    </div>
                    <div className='row'>
                        { waterFeeAmount > 0 ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formWaterFeeAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Water use amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(waterFeeAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setWaterFeeAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group>
                            </div>
                        ) : ("") }  

                        { cardSurchargeAmount > 0  ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formCardSurchargeAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Card Surcharge</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(cardSurchargeAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setCardSurchargeAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group> 
                            </div>
                        ) : ("")}
          
                    </div>

                    <div className='row'>
                        { taxAmount ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formTaxAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Tax amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(taxAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setTaxAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        ) : ("")}

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTotal">
                                <div className="d-inline-flex">
                                    <Form.Label>Total</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(total).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setTotal(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>
                            </Form.Group> 
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formAmountPaid">
                                <div className="d-inline-flex">
                                    <Form.Label>Amount paid</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(amountPaid).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setAmountPaid(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group>
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formBalance">
                                <div className="d-inline-flex">
                                    <Form.Label>Balance</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(balance).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setBalance(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <Form.Group className="mb-3" controlId="formDietary">
                        <div className="d-inline-flex">
                            <Form.Label>Dietary restrictions</Form.Label>
                        </div>
                        <Select 
                            options={dietaryList} 
                            value={dietary}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => setDietary(e)} />

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formInvoicedVia">
                        <div className="d-inline-flex">
                            <Form.Label>Payment Type</Form.Label>
                            {/* <Form.Label>Invoice Via</Form.Label>  //Asi se llamaba antes, se cambio para que el usuario pudiera ingresar una informacion mas clara*/} 
                        </div>

                        <Select 
                            options={paymentTypes} 
                            value={invoicedVia}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setInvoicedVia(e); }} />
                  
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBookingType">
                        <div className="d-inline-flex">
                            <Form.Label>Adventure type</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={bookingType === "Directly"}
                                onChange={() => changeBookingType("Directly")}
                            />Directly
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={bookingType === "Agent"}
                                onChange={() => changeBookingType("Agent")}
                            />Agent
                        </div>
                    </Form.Group> 

                    { bookingType === "Agent" ? 
                    (
                    <Form.Group className="mb-3" controlId="formAgents">
                        <div className="d-inline-flex">
                            <Form.Label>Agent</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={agentsList} 
                            value={agents}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => setAgents(e)} />

                    </Form.Group>
                    ) : ("")} 

                    <Form.Group className="mb-3" controlId="formNote">
                        <div className="d-inline-flex">
                            <Form.Label>Note</Form.Label>
                            {/* <h5 className="required">*</h5> */}
                        </div>

                        <Form.Control as="textarea" 
                            rows={15} 
                            placeholder="Here you can add the size of the suits or any other important things"
                            value={note}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => setNote(e.target.value)}
                        />

                    </Form.Group>

                </div>

                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => {{ setModal(false); setDuplicate(null); }}}>Cancel</Button>
                { userInfo?.role !== roleVisit ? ( 
                    <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {
                        var data = {
                            id,
                            adventure,
                            client,
                            clientName,
                            client_name,
                            newClientData,
                            newClient,
                            adultNumber,
                            youthNumber,
                            startDate,
                            endDate,
                            startTime,
                            endTime,
                            paid,
                            refund,
                            refundAmount,
                            tipAmount,
                            tipAmountPaid,
                            amount,
                            amountPaid,
                            taxAmount,
                            total,
                            balance,
                            bookingType,
                            invoicedVia,
                            agents,
                            dietary,
                            note,
                            taxes,
                            discount,
                            discountAmount,
                            waterFeeAmount,
                            extraAmount,
                            extraCost,
                            cardSurcharge,
                            cardSurchargeAmount,
                            cardSurchargePercentage,
                        };
                        checkData(data);
                    }
                    }>
                        Save
                    </Button>
                ) : ("")}
            </div>
        
        </form>

        <ModalC
            show={modalClient}
            onHide={() => setModalClient(false)}>
            <FormClient title={titleModalClient} lastData={{client: newClientData}} textButton={titleModalClient} action={actionClient} setModal={setModalClient}></FormClient>
        </ModalC>
        </div>

        </>
    );
}

export default FormConfig;