import { query, collection, getDocs, getDoc, doc, setDoc, deleteDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from '../firebase';
import Swal from "sweetalert2";
import { server } from './serverName/Bookings';

const isNotificationPostponed = (bookingData) => {
  // Obtenemos la fecha actual y la fecha de una semana en el futuro
  const currentDate = new Date();
  const post7Dates = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 días en milisegundos
  
  const postponed_date = bookingData?.postponed_date ? new Date(bookingData.postponed_date.seconds * 1000) : new Date(0);

  // Verifica si la notificación ha sido pospuesta o si la fecha de la notificación está en el pasado
  return !bookingData?.postponed_date || post7Dates >= postponed_date;
};

export const getDataNotifications = async (setdata, setPending = null) => {
  try {
    if (auth.currentUser != null) {
      let q = null;
      let querySnapshot = null;

      q = query(collection(db, server + "_notifications"));
      querySnapshot = await getDocs(q);

      setdata({ rows: [] });

      let array = [];
      querySnapshot.forEach((doc) => {
        const bookingData = doc.data();

        // Aplicar la lógica de posposición
        if (isNotificationPostponed(bookingData)) {
          array.push(bookingData);
        }
      });

      setdata({
        rows: array
      });

      if (setPending) {
        setPending(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getOneNotificationData = async (idDeposit) => {
  try {
    if (auth.currentUser != null) {
      // Referencia al documento específico usando el idDeposit
      const docRef = doc(db, `${server}_notifications`, idDeposit);

      // Obtener los datos del documento
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data(); // Retorna los datos del documento si existe
      } else {
        console.log("No se encontró el documento con id:", idDeposit);
        return null; // Retorna null si el documento no existe
      }
    } else {
      console.log("Usuario no autenticado");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener los datos de la notificación:", error);
    return null;
  }
};

export const addDataNotifications = async (id, data, message = false, rewrite = false) => {
  try {
    // Determinar la colección correcta según las condiciones
    const collectionName = `${server}_notifications`;

    const docRef = doc(db, collectionName, id);
    const userId = auth.currentUser;

    if (userId !== null) {
      if (rewrite) {
        // Datos de eliminación
        data["updated_by_id"] = userId.uid;
        data["updated_by_email"] = userId.email;
        data["updated_by_name"] = userId.displayName;
        data["updated_date"] = serverTimestamp();

      } else {
        // Datos de creación o actualización
        data["created_by_id"] = userId.uid;
        data["created_by_email"] = userId.email;
        data["created_by_name"] = userId.displayName;
        data["created_date"] = data?.created_date ? data?.created_date : serverTimestamp();
        data["id"] = id;

      }

      // Verificar si el documento ya existe
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        // Crear un nuevo documento
        await setDoc(docRef, data);

        if (message) {
          Swal.fire({
            icon: "success",
            title: "Added",
            text: "The notification was created successfully",
            confirmButtonColor: "#173d38",
            confirmButtonText: "Ok",
          });
        }

      } else {

        // Actualizar el documento existente
        // await updateDoc(docRef, data);

        // if (message) {
        //   Swal.fire({
        //     icon: "success",
        //     title: "Updated",
        //     text: "The notification was updated successfully",
        //     confirmButtonColor: "#173d38",
        //     confirmButtonText: "Ok",
        //   });
        // }
      }
    }
  } catch (error) {
    console.error("Error:", error);
    Swal.fire({
      icon: "error",
      title: "Error!",
      text: "Problems with the connection to the database.",
      confirmButtonColor: "#173d38",
      confirmButtonText: "OK",
    });
  }
};


export const editDataNotifications = async (edit, message = false, postponed = false) => {
    try {
      const docRef = doc(db, server + "_notifications", edit.id);
      const userId = auth.currentUser;

      edit['updated_by_id'] = userId?.uid ? userId?.uid : "Unknown-user";
      edit['updated_by_email'] = userId?.email ? userId?.email : "Unknown-user";
      edit['updated_by_name'] = userId?.displayName ? userId?.displayName : "Unknown-user"; 
      edit['updated_date'] = serverTimestamp();

      if(postponed){
        edit['postponed'] = true;
        edit['postponed_date'] = serverTimestamp();
      }

      await updateDoc(docRef,
        edit
      );

      if(message){
        Swal.fire({
          icon: "success",
          title: "Postponed",
          text: "The notification was postponed.",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Problems with the connection to the database.",
        confirmButtonColor: '#173d38',
        confirmButtonText: "OK",
      });
    }
}

export const deleteDataNotifications = async (element) => {
    try {
        // var element_duplicated = Object.assign({}, element);
        // await addDataNotifications(element_duplicated, false, false, true);

        //const docRef = doc(db, server + "_notifications", element.id);
        const docRef = doc(db, server + "_notifications", element);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}