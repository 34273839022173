import Swal from 'sweetalert2';
import { APIARRAY } from '../../emailNotification'; // Importa la configuración del API

// Función para enviar el correo con múltiples PDFs en Base64
export const sendEmailArrayPDF = async (recipient, subject, text, pdfBase64Array) => {
  try {
    // Validación inicial
    if (!recipient || !subject || !text) {
      throw new Error('Missing or invalid input: recipient, subject, or text is required.');
    }

    if (!pdfBase64Array || !Array.isArray(pdfBase64Array) || pdfBase64Array.length === 0) {
      throw new Error('No PDF files provided.');
    }

    // Construir el cuerpo de la solicitud
    const filesBase64Array = pdfBase64Array.map((base64, index) => ({
      filename: index == 0 ? `Itinerary 2024_2025.pdf` : index == 1 ? `Confirmation Contract 2024_2025.pdf` : `Terms and Conditions Transportation 2024_2025.pdf`, // Nombre del archivo con índice 
      content: base64,
      encoding: 'base64',
    }));

    const body = {
      recipient,
      subject,
      text,
      files: filesBase64Array,
    };

    // Enviar la solicitud al servidor
    const response = await fetch(`${APIARRAY}send-email-array`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to send email: ${errorText}`);
    }

    // Confirmación al usuario
    Swal.fire({
      icon: 'success',
      title: 'Email sent successfully!',
      text: 'The email has been sent successfully.',
      confirmButtonColor: '#173d38',
      confirmButtonText: 'Ok',
    });

  } catch (error) {
    console.error('Failed to send email:', error);

    // Notificar al usuario en caso de error
    Swal.fire({
      icon: 'error',
      title: 'Email failed!',
      text: error.message || 'Failed to send email. Please try again later.',
      confirmButtonColor: '#173d38',
      confirmButtonText: 'Ok',
    });
  }
};
