import jsPDF from 'jspdf';
import imgw from "../../../assets/Word_Logo_Color_Cream_compress_2.png";


async function termsConditionsPDF(data) {
  const doc = new jsPDF();
  const compressedImgw = await compressImage(imgw);

  /////////////////*****************************/////////////////
  // Obtener las dimensiones de la imagen
  const imgWidthTree = 220; // Ancho de la imagen en unidades PDF
  const imgHeightTree = 170; // Alto de la imagen en unidades PDF

  // Obtener las dimensiones del PDF
  const pdfWidth = doc.internal.pageSize.width;
  const pdfHeight = doc.internal.pageSize.height;

  // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
  const x = (pdfWidth - imgWidthTree) / 2; // Centrar horizontalmente
  const y = pdfHeight - imgHeightTree; // Colocar en la parte inferior

  // Agregar la imagen al PDF
  // doc.addImage(compressedImgTrees, 'JPEG', x, y, imgWidthTree, imgHeightTree);
  /////////////////*****************************/////////////////


  // Company image
  const imgWidth = 160;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 25;
  // const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  const imgMarginLeft = 25;
  // doc.addImage(compressedImgTrees, 'JPG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
  doc.addImage(imgw, "JPG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Dibujar una línea amarilla debajo de la imagen
const lineY = imgMarginTop + imgHeight + 10; // Calculamos la posición Y para la línea (debajo de la imagen)
doc.setDrawColor(222, 168, 69); // Establecer el color amarillo (RGB)
doc.setLineWidth(1); // Establecer el grosor de la línea
doc.line(imgMarginLeft, lineY, imgMarginLeft + imgWidth, lineY); // Dibujar la línea

// Agregar el texto proporcionado al PDF
doc.setFontSize(12);
doc.setFont('helvetica', 'bold');
doc.text("Terms & Conditions", 87, 80);
doc.text("Cancellation Policy:", 20, 90);

// Restaurar la fuente normal
doc.setFontSize(11);
doc.setFont('helvetica', 'normal');

doc.text(`• Airport cancellations received 72 hours or more from the scheduled pick-up date and time` , 25, 100);  
doc.text(`will receive a full refund minus an administrative fee of $50+ GST.` , 28, 105);  
doc.text(`• Private Charter cancellations received fourteen(14) days or more from the scheduled pick-up`, 25, 110);
doc.text(`date and time will receive a full refund minus an administrative fee of $150 + GST.`, 28, 115);
doc.text(`• All reservations, Adventure or Transport, are non-refundable if canceled within 72 hours of`, 25, 120);
doc.text(`the scheduled pick-up time.`, 28, 125);
doc.text(`• Cancellations must be received by telephone or email and confirmed in writing.`, 25, 130);
doc.text(`• Changes made to your booking within 48 hours before departure will depend on availability`, 25, 135);
doc.text(`and cannot be guaranteed.`, 28, 140);
doc.text(`• Tunnel49 reserves the right to change or cancel any trip due to weather or other unforeseen`, 25, 145);
doc.text(`circumstances.`, 28, 150);
doc.text(`• Certain exceptions apply to reasons for cancellation.`, 25, 155);
doc.text(`• Changes due to flight delays or cancellations will be rescheduled at no extra charge.`, 25, 160);


// Aumentar el tamaño de la fuente y negrita
doc.setFontSize(12);
doc.setFont('helvetica', 'bold');
doc.text("Operating Policy:", 20, 170);

// Restaurar el tamaño de la fuente normal
doc.setFontSize(11);
doc.setFont('helvetica', 'normal');

doc.text(`• Tunnel49 will not be responsible for delays or missed flights resulting from inclement weather,`, 25, 180);
doc.text(`unforeseen road closures, highway construction, abnormally heavy traffic, or any other `, 28, 185);
doc.text(`conditions outside of its control. Be aware that weather, traffic, or unforeseen road  `, 28, 190);
doc.text(`closures can severely impact mountain travel times. Trip interruption Insurance or `, 28, 195);
doc.text(`Cancellation Insurance is advised.`, 28, 200);
doc.text(`• Private charter day rates are predicated upon 12 hours of service from the moment that the`, 25, 205);
doc.text(`driver starts a Tunnel49 vehicle until the moment that same vehicle is turned off at our home`, 28, 210);
doc.text(`base (802, Highway #3, Fernie, B.C.) or a hotel.`, 28, 215);
doc.text(`• If a half-day private charter (5-hours of service) exceeds the agreed-upon service hours`, 25, 220);
doc.text(`Tunnel49 is liable to charge the full-day rate for the vehicle in use.`, 28, 225);
doc.text(`• Tunnel49 reserves the right to cancel all trips that reach -20 degrees Celsius or lower.`, 25, 230);
doc.text(`• In the event of a flight delay, drivers will wait for 15 minutes at no additional charge. Parties`, 25, 235);
doc.text(`delayed beyond 15 minutes from the requested pick-up time, will be billed at $99/hour plus`, 28, 240);
doc.text(`5% GST in 15-minute increments.`, 28, 245);



  // Verificar si la altura del contenido excede el límite de una sola página
  if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
  } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    //////////////********************************/////////////////

    doc.text(`• For departure flights, travelers must be ready 10 minutes before the scheduled pick-up time.`, 25, 20);
    doc.text(`On busy winter days, be ready 1 hour before the set departure time. A member of our staff`, 28, 25);
    doc.text(`will be in touch to coordinate pick-up times and locations. Any delays may result in additional`, 28, 30);
    doc.text(`charges. This means having your luggage and group waiting at the pick-up location. Please`, 28, 35);
    doc.text(`note that it takes over three (3) hours to get to the Calgary International Airport and over`, 28, 40);
    doc.text(`one (1) hour to get to Cranbrook International Airport from Fernie.`, 28, 45);

    doc.text(`• Additionally, customers should plan to arrive at the airport at the Airline's recommended time`, 25, 50);
    doc.text(`guidelines; Please ensure you've given yourself enough time. Tunnel49 is not responsible`, 28, 55);    
    doc.text(`for any missed flights.`, 28, 60);
    doc.text(`• YXC Arrivals - Tunnel49 will meet clients at the curb-side arrival doors of the Canadian`, 25, 65);    
    doc.text(`Rockies International Airport. Our shuttle vehicle can be contacted via mobile phone or`, 28, 70);
    doc.text(`email. Flight numbers must be provided.`, 28, 75);    
    doc.text(`• YYC Arrivals - Tunnel49 will meet clients outside Door 12 of the Departures level, near the`, 25, 80);
    doc.text(`Marriott Hotel. Contact numbers or emails should be provided at the time of booking so the `, 28, 85);
    doc.text(`driver can contact guests. Flight numbers must be provided. Pick-up from YYC is at 13:00`, 28, 90);
    doc.text(`daily.`, 28, 95);


    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("Confirmation of reservation:", 20, 105);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`• Airport shuttles: Payment is required at the time of booking to confirm your reservation.`, 25, 115);
    doc.text(`• Private Charters: 50% deposit is required, and full payment is due 7 days before the charter.`, 25, 120);
    doc.text(`• Payment can be made over the phone with a credit card, or by an online payment link.`, 25, 125);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("Billing Policy:", 20, 135);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`• Additional charges will apply to guests who are 15 minutes late for their scheduled pick-up time,`, 25, 145);
    doc.text(`guests who fail to follow our no-smoking rule, and to guests who have additional luggage.`, 28, 150);
    doc.text(`• Payments can be made by VISA, MasterCard, and AMEX.`, 25, 155);
    doc.text(`• Prices are in Canadian dollars and do not include applicable taxes.`, 25, 160);
    doc.text(`• If the Client chooses to make payments using a credit card, a surcharge of 2.4% will be applied`, 25, 165);
    doc.text(`to the total amount due.`, 28, 170);    
    doc.text(`• The 2.4% surcharge will be calculated based on the total amount of each credit card transaction`, 25, 175);
    doc.text(`and will be added to the final invoice amount.`, 28, 180);  
    doc.text(`• By choosing to pay via credit card, the Client acknowledges and agrees to the application of`, 25, 185);
    doc.text(`this surcharge.`, 28, 190); 
    

     // Aumentar el tamaño de la fuente y negrita
     doc.setFontSize(12);
     doc.setFont('helvetica', 'bold');
     doc.text("Refund Policy:", 20, 205);
 
     // Restaurar el tamaño de la fuente normal
     doc.setFontSize(11);
     doc.setFont('helvetica', 'normal');
    
    doc.text(`• It is the guest's responsibility to check the itinerary and confirm all information is correct.`, 25, 215);
    doc.text(`• Refunds will not be issued to guests who are denied admission into Canada.`, 25, 220); 
    doc.text(`• It is the guest's responsibility to arrive at the dates and times outlined in the confirmation`, 25, 225);
    doc.text(`notice, and as per the guidelines above.`, 28, 230);
    doc.text(`• In the event of circumstances out of our control, Tunnel49 reserves the right to cancel any`, 25, 235);
    doc.text(`booking without notice. Tunnel49 will make its best effort to reschedule the booking. If a `, 28, 240);
    doc.text(`suitable time cannot be found, a refund will be offered, but under no circumstances will `, 28, 245);
    doc.text(`Tunnel49 be responsible for the guests inconvenience or travel expenses.`, 28, 250);


    // (footer)
    // doc.setFillColor(23, 61, 56);
    // doc.rect(
    //   0,
    //   doc.internal.pageSize.height - 10,
    //   doc.internal.pageSize.width,
    //   20,
    //   "F"
    // );

    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
      // Si el contenido cabe en una sola página, devolver el PDF
      return doc.output('datauristring');
    } else {
      // Si el contenido excede el límite de una sola página, agregar una nueva página
      doc.addPage();

      /////////////////*****************************/////////////////   



    doc.text(`• Please ensure you have cancellation or trip interruption insurance, as Tunnel49 is not `, 25, 20);
    doc.text(`responsible for any delays that may happen due to road closures, weather, or bad traffic.`, 28, 25);
    doc.text(`• There will be no refunds or rescheduling for guests who do not arrive on time.`, 25, 30);


      // Aumentar el tamaño de la fuente y negrita
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text("Surcharge Policy:", 20, 40);
  
      // Restaurar el tamaño de la fuente normal
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

    doc.text(`• Tunnel49 Adventure Inc. will charge a 9% fuel surcharge, along with 5% for GST for our airport`, 25, 50);
    doc.text(`shuttles. This is not reflected in our prices and will be charged on top of your quoted price.`, 28, 55);
    doc.text(`• Early morning pick-up requests (before 6 am) and late night pick-up requests (after 10 pm) are `, 25, 60);
    doc.text(`charged a $99 surcharge for out-of-business-hours operations.`, 28, 65);
    doc.text(`• At Tunnel49 our rates do not include a driver gratuity. We believe that should be up to the `, 25, 70);
    doc.text(`customer's discretion. If  you felt like your driver provided outstanding customer service, you`, 28, 75);
    doc.text(`may wish to do so by gratuity. If you wish to have this added to your total please let us know`, 28, 80);
    doc.text(`beforehand or on the day of. Alternatively, you can bring cash and thank your driver in person.`, 28, 85);
    doc.text(`The traditional rate to use for gratuities in Canada is approximately fifteen percent (15%).`, 28, 90);

    
      // Aumentar el tamaño de la fuente y negrita
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text("Luggage Allowance:", 20, 100);
  
      // Restaurar el tamaño de la fuente normal
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');


    doc.text(`• Airport shuttle tickets include two (2) pieces of luggage, plus one (1) carry-on piece of luggage. `, 25, 110);
    doc.text(`An additional charge of $20.00 will apply to any extra piece of luggage.`, 28, 115);
    doc.text(`• Please notify staff upon booking of how much luggage you or your group will have and if you`, 25, 120);
    doc.text(`have any oversized items.`, 28, 125);
    doc.text(`• Should a trailer be required, it will be billed at $149/per day.`, 25, 130);
    doc.text(`• Larger luggage like bikes, skis/snowboards, musical instruments, and other oversized items`, 25, 135);
    doc.text(`should be packed accordingly. Tunnel49 is not responsible for any damage that may occur`, 28, 140);
    doc.text(`during transit.`, 28, 145);
    doc.text(`• Tunnel49 drivers will load/unload your baggage for you, but it is the customer's responsibility`, 25, 150);
    doc.text(`to see that it has been loaded. Tunnel49 will not be responsible for lost or left luggage that`, 28, 155);
    doc.text(`was due to customer negligence.`, 28, 160);

    
      // Aumentar el tamaño de la fuente y negrita
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text("Cleaning fee:", 20, 170);

      // Restaurar el tamaño de la fuente normal
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');


    doc.text(`• A cleaning fee of $150 will be charged in the instance that the vehicle is left unusually or`, 25, 180);
    doc.text(`unnecessarily tidy. Up to  $500 can be charged for major deep cleaning.`, 28, 185);
    doc.text(`• A $200 minimum fee will be charged to anyone smoking or vaping in our vehicles.`, 25, 190);


      // Aumentar el tamaño de la fuente y negrita
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text("Pets:", 20, 200);
  
      // Restaurar el tamaño de la fuente normal
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');

    doc.text(`• Pets are not permitted except for service animals. Please notify our staff upon booking if`, 25, 210);
    doc.text(`you will be traveling with a service animal.`, 28, 215);

    // doc.text(``, 28, 220);
    // doc.text(``, 28, 220);


    // Aumentar el tamaño de la fuente y negrita
    // doc.setFontSize(12);
    // doc.setFont('helvetica', 'bold');
    // doc.text("Confirmation of reservation:", 20, 105);

    // // Restaurar el tamaño de la fuente normal
    // doc.setFontSize(11);
    // doc.setFont('helvetica', 'normal');



  }

    // Obtener los datos binarios del PDF como una cadena
    const pdfData = doc.output();

    // Codificar los datos binarios en base64
    const pdfBase64 = btoa(pdfData);

    // Devolver los datos codificados en base64
    return pdfBase64;
    
  }

}

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = 'white'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};


export default termsConditionsPDF;
