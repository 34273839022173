import jsPDF from 'jspdf';
import imgw from "../../../assets/Word_Logo_Color_Cream_compress_2.png";


async function contractConfirmation(data) {
  const doc = new jsPDF();
  const compressedImgw = await compressImage(imgw);

  /////////////////*****************************/////////////////
  // Obtener las dimensiones de la imagen
  const imgWidthTree = 220; // Ancho de la imagen en unidades PDF
  const imgHeightTree = 170; // Alto de la imagen en unidades PDF

  // Obtener las dimensiones del PDF
  const pdfWidth = doc.internal.pageSize.width;
  const pdfHeight = doc.internal.pageSize.height;

  // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
  const x = (pdfWidth - imgWidthTree) / 2; // Centrar horizontalmente
  const y = pdfHeight - imgHeightTree; // Colocar en la parte inferior

  // Agregar la imagen al PDF
  // doc.addImage(compressedImgTrees, 'JPEG', x, y, imgWidthTree, imgHeightTree);
  /////////////////*****************************/////////////////


  // Company image
  const imgWidth = 160;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 25;
  // const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  const imgMarginLeft = 25;
  // doc.addImage(compressedImgTrees, 'JPG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
  doc.addImage(imgw, "JPG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

    // Agregar el texto proporcionado al PDF
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("CONFIRMATION CONTRACT", 130, 70);

    // Restaurar la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`Thank you for booking with Tunnel49 Ltd.!`, 20, 80);

    doc.text(`Please ensure that the trip details are correct as below. If any corrections are required, please`, 20, 90);
    doc.text(`contactour office as soon as possible. Please take the time to read through all our Policies. This`, 20, 95);
    doc.text(`contract is for the entire 2024/2025 season.`, 20, 100);

    doc.text(`Group:`, 20, 110);
    doc.text(data?.clientName ? data?.clientName : data?.client?.label ? data?.client?.label : data?.newClientData, 35, 110);
    doc.text(`Itinerary:`, 20, 120);
    doc.text(data?.pickUp?.label ? data?.pickUp?.label : "" + " - " + data?.pickUp?.dropOff ? data?.pickUp?.dropOff : "", 37, 120);
    doc.text(`Vehicle:`, 20, 130);
    doc.text(data?.shuttle?.label ? data?.shuttle?.label : "", 35, 130);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text("CHARTER SERVICES TERMS AND AGREEMENT:", 20, 145);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`Tunnel49 will be providing the client with the following vehicles in priority (school buses may`, 20, 155);
    doc.text(`be provided in the case of a breakdown):`, 20, 160);

    doc.text(`Initial:_________________________`, 20, 185);


    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`12 hour overage charges:`, 20, 195);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`In adherence to Tunnel49's operational policies, pricing structures are predicated upon a 12-hour`, 20, 205);
    doc.text(`day timeframe from service initiation. Should Tunnel49 drivers exceed this specified duration,`, 20, 210);
    doc.text(`additional charges will be incurred to offset operational expenses. Consequently, Tunnel49 will`, 20, 215);
    doc.text(`commence charging an extra amount of $208.33 to mitigate incurred costs. This will ONLY be`, 20, 220);
    doc.text(`charged if we exceed our 12-hour timeframe. All hours are consecutive. The day rate is defined`, 20, 225);
    doc.text(`as the time a driver starts the bus until the end of the day when the bus is turned off and the`, 20, 230);
    doc.text(`driver is finished, whether at home base or in a hotel.`, 20, 235);

    doc.text(`Initial:_________________________`, 20, 260);



  // Verificar si la altura del contenido excede el límite de una sola página
  if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
  } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    //////////////********************************/////////////////


    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Communicable Disease Precautions and Requirements:`, 20, 30);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');


    doc.text(`To ensure the safety and well-being of our staff &amp; customers, Tunnel49 has developed a`, 20, 40);
    doc.text(`Communicable Disease Prevention Plan that has been implemented to the extent feasible and`, 20, 45);
    doc.text(`appropriate throughout Tunnel49 premises and vehicles. All vehicles are cleaned and sanitized `, 20, 50);
    doc.text(`before and following every charter. All passengers are responsible for completing their health `, 20, 55);
    doc.text(`checks and following cleanliness protocols (washing hands regularly, using hand sanitizer, `, 20, 60);
    doc.text(`staying home if ill). They are welcome to  wear masks if they so choose. Hand sanitizer is made`, 20, 65);
    doc.text(`readily available. **Please note that as provincial health orders/protocols change so will our`, 20, 70);
    doc.text(`contract and customer requirements. We will keep you updated.`, 20, 75);

    doc.text(`Initial:_________________________`, 20, 100);



    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Itinerary, Changes and Prices:`, 20, 115);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');


    doc.text(`A complete detailed itinerary is to be provided at least 14 days before departure for approval for`, 20, 125);
    doc.text(`our driver to provide you with the excellent service expected. The itinerary must include all stops`, 20, 130);
    doc.text(`required by our driver. The prices are based on the most direct route to your destination. Any`, 20, 135);
    doc.text(`changes to the itinerary may impact the driver's ability to comply with the Federal hours of service`, 20, 140);
    doc.text(`regulations and therefore must be approved by Tunnel49 head office before the start of the charter.`, 20, 145);
    doc.text(`Any changes made after the charter has been reserved (deposit and/or full payment has been paid)`, 20, 150);
    doc.text(`that increase the driver's wait time, drive time, and /or distance traveled will result in additional`, 20, 155);
    doc.text(`charges. These fees will be billed to the customer upon completion of the charter.`, 20, 160);

    doc.text(`Initial:_________________________`, 20, 185);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Payment:`, 20, 200);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`50% of the balance will be charged during the initial booking to secure the day. 14-days prior to`, 20, 210);
    doc.text(`the event the remaining balance will be charged. Accepted forms of payment include credit card, `, 20, 215);
    doc.text(`e-transfer, and cheque.`, 20, 220);

    doc.text(`Initial:_________________________`, 20, 245);

    

    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
      // Si el contenido cabe en una sola página, devolver el PDF
      return doc.output('datauristring');
    } else {
      // Si el contenido excede el límite de una sola página, agregar una nueva página
      doc.addPage();

      /////////////////*****************************///////////////// 
      
    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Additional Fees:`, 20, 30);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
      

    doc.text(`If your trip extends beyond the agreed-upon time for the half-day rate, Tunnel49 is liable to charge`, 20, 40);
    doc.text(`the full-day rate for the vehicle you booked. If the vehicle is not left in a respectable condition`, 20, 45);
    doc.text(`an additional cleaning fee charge is $150.00.`, 20, 50);

    doc.text(`Initial:_________________________`, 20, 75);


    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Cancellation Policy:`, 20, 90);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');


    doc.text(`Cancellations will be accepted in writing only (emails acceptable). Refunds will be processed as:`, 20, 100);
    doc.text(`follows:`, 20, 105);

    doc.text(`- If canceled more than 14 days before the trip start date, 100% refund minus $150`, 25, 115);
    doc.text(`administrative fee.`, 25, 120);
    doc.text(`- If canceled between 14 days before the trip start date, 50% of the full cost will be`, 25, 125);
    doc.text(`refunded; - No shows or on-the-spot cancellations, no refund.`, 25, 130);
    doc.text(`- If weather reaches below -25 degrees Celsius, Tunnel49 reserves the right to cancel a`, 25, 135);
    doc.text(`charter at any time. A full refund will be given if Tunnel49 is responsible for cancellation.`, 25, 140);
    doc.text(`- If your group has a charter breakdown and Tunnel49 is responsible, a full refund will be`, 25, 145);
    doc.text(`granted.`, 25, 150);
    doc.text(`- Returning earlier than originally booked, no refund.`, 25, 155);

    doc.text(`Initial:_________________________`, 20, 180);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Equipment and Licensing:`, 20, 195);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');


    doc.text(`All Tunnel49 vehicles are maintained on a regular and as-required basis to ensure passenger`, 20, 205);
    doc.text(`comfort and safety. All are inspected as per National Safety Code regulations every 6 months.`, 20, 210);
    doc.text(`Tunnel49 will not be held liable for any additional costs that may be incurred by the chartering`, 20, 215);
    doc.text(`party such as meals, rooms, or penalties because of delays due to mechanical failure, weather,`, 20, 220);
    doc.text(`or unforeseen circumstances.`, 20, 225);

    doc.text(`Initial:_________________________`, 20, 250);

    doc.text(``, 20, 220);
    doc.text(``, 20, 220);
    doc.text(``, 20, 220);


    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
    } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    /////////////////*****************************///////////////// 

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Driving Regulations:`, 20, 30);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`Tunnel49 is committed to the safety of all passengers and therefore our drivers are expected `, 20, 40);
    doc.text(`to comply at all times with the Federal hours of service regulations. Delays, unplanned stops, `, 20, 45);
    doc.text(`detours, etc., not on the original itinerary could place the driver in a non-compliance situation`, 20, 50);
    doc.text(`that is punishable by severe fines and out-of-service orders. The driver reserves the right to`, 20, 55);
    doc.text(`refuse to drive on unimproved roads if there is a danger to the safety of the passengers or`, 20, 60);
    doc.text(`damage to the vehicle. Minors on board the bus must be supervised at all times by an`, 20, 65);
    doc.text(`adult(s) from the group. The drivers will not act as supervisors.`, 20, 70);

    doc.text(`Initial:_________________________`, 20, 95);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Baggage:`, 20, 110);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`Baggage and all personal property will be handled only at the passenge's risk and only in the`, 20, 120);
    doc.text(`amount that can conveniently be carried in the bus. Anything left on the bus overnight is at`, 20, 125);
    doc.text(`the owner's risk. Tunnel49 does not assume any responsibility for any personal property.`, 20, 130);

    doc.text(`Initial:_________________________`, 20, 155);


    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Food & Beverages:`, 20, 170);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`There is no cooking on the bus (crockpots, slow cookers, etc.). Suggested foods to consume`, 20, 180);
    doc.text(`on board are sandwiches, muffins, pizza, subs, and closeable drink containers. Coffee and`, 20, 185);
    doc.text(`hot beverages must be in thermoses/travel mugs. Any garbage must be disposed of`, 20, 190);
    doc.text(`appropriately. Prohibited items include sunflower seeds and chewing gum. Cleaning more`, 20, 195);
    doc.text(`than normal (due to food spillage) will incur additional charges.`, 20, 200);

    doc.text(`Initial:_________________________`, 20, 225);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Alcohol, Smoking, Illegal Drugs, Chewing Tobacco:`, 20, 240);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`Alcohol is not permitted on board as per British Columbia Legislation. Any alcohol is to be`, 20, 250);
    doc.text(`stored with the luggage. Smoking of any type, including but not limited to tobacco, marijuana,`, 20, 255);




    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
    // Si el contenido cabe en una sola página, devolver el PDF
    return doc.output('datauristring');
    } else {
    // Si el contenido excede el límite de una sola página, agregar una nueva página
    doc.addPage();

    /////////////////*****************************///////////////// 

    
    doc.text(`or vaping, is prohibited on the bus. Any smoking must be done outside and at a minimum of`, 20, 30);
    doc.text(`10 feet from the vehicle, as per British Columbia Regulations. Chewing Tobacco cannot be `, 20, 35);
    doc.text(`consumed/used on the coach. All passengers must comply with provincial and federal laws`, 20, 40);
    doc.text(`regarding illegal drugs.`, 20, 45);

    doc.text(`Initial:_________________________`, 20, 70);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Credit Card Surcharge:`, 20, 85);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.text(`1. Credit Card Payments:`, 20, 95);
    doc.setFont('helvetica', 'normal');


    doc.text(` If the Client chooses to make payments using a credit card, a`, 67, 95);
    doc.text(`surcharge of 2.4% will be applied to the total amount due.`, 20, 100);


    // Pasa la fuenta a negrita
    doc.setFont('helvetica', 'bold');
    doc.text(`2. Application of Surcharge:`, 20, 110);

    // Restaurar el tipo fuente a normal
    doc.setFont('helvetica', 'normal');

    doc.text(`The 2.4% surcharge will be calculated based on the total`, 74, 110);
    doc.text(`amount of each credit card transaction and will be added to the final invoice amount.`, 20, 115);


    // Pasa la fuenta a negrita
    doc.setFont('helvetica', 'bold');
    doc.text(`3. Acknowledgment: `, 20, 125);

    // Restaurar el tipo fuente a normal
    doc.setFont('helvetica', 'normal');

    doc.text(`By choosing to pay via credit card, the Client acknowledges and`, 60, 125);
    doc.text(`agrees to the application of this surcharge.`, 20, 130);

    // Pasa la fuenta a negrita
    doc.setFont('helvetica', 'bold');
    doc.text(`4. Alternative Payment Methods:`, 20, 140);

    // Restaurar el tipo fuente a normal
    doc.setFont('helvetica', 'normal');


    doc.text(` The Client may opt for alternative payment methods that`, 80, 140);
    doc.text(`do not incur a surcharge. Alternative payment options include e-transfer to info@t49.ca,`, 20, 145);
    doc.text(`wire transfer, or cheque.`, 20, 150);

    doc.text(`Initial:_________________________`, 20, 175);

    // Aumentar el tamaño de la fuente y negrita
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`Extra Cleaning & Damages:`, 20, 190);

    // Restaurar el tamaño de la fuente normal
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

    doc.text(`If any damage is caused by any person in the booking group, the group will be responsible for `, 20, 200);
    doc.text(`reimbursing Tunnel49 for repairing said damages. If the groups activities require greater than`, 20, 205);
    doc.text(`normal cleaning time and material, additional charges will be added. Please note that 2`, 20, 210);
    doc.text(`garbage cans on the shuttle can be used for catching any refuse.`, 20, 215);

    doc.text(`Initial:_________________________`, 20, 240);


    
    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
        // Si el contenido cabe en una sola página, devolver el PDF
        return doc.output('datauristring');
        } else {
        // Si el contenido excede el límite de una sola página, agregar una nueva página
        doc.addPage();
    
        /////////////////*****************************///////////////// 

        // Aumentar el tamaño de la fuente y negrita
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Border Crossing:`, 20, 30);

        // Restaurar el tamaño de la fuente normal
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
    
        
        doc.text(`All Covid-19 emergency border measures have ended. All that passengers traveling to and`, 20, 40);
        doc.text(`from the United States require is proper identification and documentation to prove their`, 20, 45);
        doc.text(`citizenship (current valid passport). If these regulations change, we will inform you of`, 20, 50);
        doc.text(`the current requirements at the time of your charter.`, 20, 55);
        doc.text(`Tunnel49 has a zero-tolerance policy on smuggling. All individuals must claim any goods`, 20, 60);
        doc.text(`purchased in the US/Canada upon return to Canada/US. Any individual who is detained at`, 20, 65);
        doc.text(`customs for smuggling goods will not be allowed back on the bus and must find their own`, 20, 70);
        doc.text(`transportation home.`, 20, 75);

        doc.text(`Initial:_________________________`, 20, 100);

        // Aumentar el tamaño de la fuente y negrita
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Accommodations:`, 20, 115);

        // Restaurar el tamaño de la fuente normal
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');

        doc.text(`For all overnight stays, the group is responsible for booking and paying for the driver's private`, 20, 125);
        doc.text(`hotel room. Parking a large vehicle must be taken into consideration when planning`, 20, 130);
        doc.text(`accommodation arrangements, this includes a block heater plug-in and a wide enough`, 20, 135);
        doc.text(`parking space for the coach to be able to turn around. If the bus needs to be parked off-site`, 20, 140);
        doc.text(`due to no room at the hotel, the booking party must make prior bus parking arrangements`, 20, 145);
        doc.text(`and pay for the transportation of the driver to and from the bus/hotel.`, 20, 150);

        doc.text(`Initial:_________________________`, 20, 175);

        // Aumentar el tamaño de la fuente y negrita
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Parking & Other Fees:`, 20, 190);

        // Restaurar el tamaño de la fuente normal
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');
        
        doc.text(`The booking party is responsible for the booking and payment of any parking, ferry transportation,`, 20, 200);
        doc.text(`park permits, etc., in advance of the charter. Copies of receipts/bookings must be provided to`, 20, 205);
        doc.text(`Tunnel49 before the charter.`, 20, 210);

        doc.text(`Initial:_________________________`, 20, 235);


        
    if (doc.internal.pageSize.height < 290) { // Ajusta el valor 290 según sea necesario
        // Si el contenido cabe en una sola página, devolver el PDF
        return doc.output('datauristring');
        } else {
        // Si el contenido excede el límite de una sola página, agregar una nueva página
        doc.addPage();
    
        /////////////////*****************************///////////////// 

        // Aumentar el tamaño de la fuente y negrita
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Lost & Found:`, 20, 30);

        // Restaurar el tamaño de la fuente normal
        doc.setFontSize(11);
        doc.setFont('helvetica', 'normal');

        doc.text(`Any items found on the bus after the trip returns to our office will be held for 2 weeks. The`, 20, 40);
        doc.text(`booking party will be contacted. The passenger is responsible for pick-up of the item(s) or`, 20, 45);
        doc.text(`the arrangements for shipping, at the passenger's expense.`, 20, 50);

        doc.text(`Initial:_________________________`, 20, 75);

        doc.text(`Please acknowledge confirmation of the details and acceptance of the terms by signing below`, 20, 90);
        doc.text(`and return by email to info@t49.ca.`, 20, 95);

        doc.text(`__________________________________`, 20, 120);
        doc.text(`Signature`, 20, 125);

        doc.text(`__________________________________`, 110, 120);
        doc.text(`Printed Name`, 110, 125);

        doc.text(`__________________________________`, 20, 150);
        doc.text(`Date Signed`, 20, 155);

    
        }
    
    
        }


    }


    }

    }

    // Obtener los datos binarios del PDF como una cadena
    const pdfData = doc.output();

    // Codificar los datos binarios en base64
    const pdfBase64 = btoa(pdfData);

    // Devolver los datos codificados en base64
    return pdfBase64;
    
  }

}

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = 'white'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};


export default contractConfirmation;
