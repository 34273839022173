import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc, where } from "firebase/firestore";
import { auth, dbCrm } from '../../firebase'
import { ExcelRenderer } from 'react-excel-renderer';
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { serverCrm } from '../serverName/Crm';
import { sendEmailMarketingService } from '../../services/Services';

export const fileHandler = (event, setData) => {
  let fileObj = event.target.files[0];
  //just pass the fileObj as parameter
  ExcelRenderer(fileObj, async (err, resp) => {
    var array = [];
    if (err) {
      console.log(err);
    }
    else {
      resp.rows.forEach(async (element, i) => {
        if (i !== 0 && element[0]) {
          var newObject = {
            id: element[0] ? element[0] : '',
            client: element[1] ? element[1] : '',
            organisation: element[2] ? element[2] : '',
            email: element[3] ? element[3] : '',
            phone: element[4] ? element[4] : '',

            source: element[5] ? element[5] : '',
            status: element[6] ? element[6] : '',
            owner: element[7] ? element[7] : '',
            date: element[8] ? element[8] : '',
            modified: element[9] ? element[9] : '',

            value: element[10] ? element[10] : '',
            requested: element[11] ? element[11] : '',
            nop: element[12] ? element[12] : '',
            request_type: element[13] ? element[13] : '',
            feedback: element[14] ? element[14] : '',
            vehicle: element[15] ? element[15] : '',
          }

          if (newObject.id != '') {
            await editData(newObject, false, true);
          } else {
            await addData(newObject);
          }

          array.push(newObject);
        }
      });
      setData({ rows: array });
    }

    Swal.fire({
      icon: "success",
      title: "Imported",
      text: "The clients was created successfully",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  });
}

export const addData = async (data, message = false, del = false) => {
  try {
    var id = uuid();
    const docRef = doc(
        dbCrm,
      del ? (serverCrm === 'clients' ? "plan_1_delete_911" : 'dev_delete_911') : serverCrm,
      id
    );
    const userId = auth.currentUser;
    let today = new Date();
    let now = today.toLocaleString();
    
    if (userId !== null) {
      data['created_by_id'] = userId.uid;
      data['created_by_email'] = userId.email;
      data['created_by_name'] = userId.displayName;
      data['created_date'] = now;
      data['id'] = id;

      if (del) {
        data['deleted_by_id'] = userId.uid;
        data['deleted_by_email'] = userId.email;
        data['deleted_by_name'] = userId.displayName;
        data['deleted_date'] = now;
      }
      await setDoc(docRef, data);

      if (message) {
        Swal.fire({
          icon: "success",
          title: "Added",
          text: "The client was created successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const editData = async (edit, message = true, excel = false) => {
  try {
    const docRef = doc(dbCrm, serverCrm, edit.id);
    const userId = auth.currentUser;
    let today = new Date();
    let now = today.toLocaleString();
    
    edit['updated_by_id'] = userId.uid;
    edit['updated_by_email'] = userId.email;
    edit['updated_by_name'] = userId.displayName;
    edit['updated_date'] = now;

    //edit['date'] = formatDate(numtoDate(edit['date']), 'yyyy-MM-dd');

    await updateDoc(docRef,
      edit
    );

    if (message) {
      Swal.fire({
        icon: "success",
        title: "Edited",
        text: "The client was edited successfully",
        confirmButtonColor: '#173d38',
        confirmButtonText: "Ok",
      });
    }
  } catch (error) {
    // If file no exist, it must be created
    if (excel)
      addData(edit);

    console.log(error);
  }
}

export const getData = async (setdata, setPending = null) => {
  try {
    if (auth.currentUser != null) {
      const q = query(collection(dbCrm, serverCrm));
      const querySnapshot = await getDocs(q);

      setdata({ rows: [] });

      var array = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });
      setdata({
        rows: array
      });

      if (setPending) {
        setPending(false);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const deleteData = async (element) => {
  try {
    var element_duplicated = Object.assign({}, element);
    await addData(element_duplicated, false, true);

    const docRef = doc(dbCrm, serverCrm, element.id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
}

// export const sendAutomatedEmailMarketing = async (element) => {
//   try {

//     //console.log("element", element);
//     sendEmailMarketingService(element);


//     // var element_duplicated = Object.assign({}, element);
//     // await addData(element_duplicated, false, true);

//     // const docRef = doc(dbCrm, serverCrm, element.id);
//     // await deleteDoc(docRef);
//   } catch (error) {
//     console.log(error);
//   }
// }

export const VerifyClientExists = async (name) => {
  try {
    let exist1 = false

    if (auth.currentUser != null) {

        const all = query(collection(dbCrm, serverCrm === 'clients' ? "clients" : 'dev'), where('client', '==', name));
        const querySnapshot = await getDocs(all);

        var array = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          array.push(data);
        });
  
        if(array?.length > 0){
          exist1 = true;
        }

    }
    return exist1;

  } catch (error) {
    console.log(error);
  }
}

