import jsPDF from 'jspdf';
import imgw from "../../../assets/Word_Logo_Color_Cream_compress_2.png";
import 'jspdf-autotable';


async function itineraryConfirmation(data) {
  const doc = new jsPDF();
  const compressedImgw = await compressImage(imgw);

  /////////////////*****************************/////////////////
  // Obtener las dimensiones de la imagen
  const imgWidthTree = 220; // Ancho de la imagen en unidades PDF
  const imgHeightTree = 170; // Alto de la imagen en unidades PDF

  // Obtener las dimensiones del PDF
  const pdfWidth = doc.internal.pageSize.width;
  const pdfHeight = doc.internal.pageSize.height;

  // Calcular las coordenadas para colocar la imagen en la parte inferior del PDF
  const x = (pdfWidth - imgWidthTree) / 2; // Centrar horizontalmente
  const y = pdfHeight - imgHeightTree; // Colocar en la parte inferior

  // Agregar la imagen al PDF
  // doc.addImage(compressedImgTrees, 'JPEG', x, y, imgWidthTree, imgHeightTree);
  /////////////////*****************************/////////////////


  // Company image
  const imgWidth = 160;
  const imgOriginalWidth = 8105;
  const imgOriginalHeight = 1641;
  const imgHeight = (imgWidth * imgOriginalHeight) / imgOriginalWidth;
  const imgMarginTop = 15;
  // const imgMarginLeft = (doc.internal.pageSize.width - imgWidth) / 2;
  const imgMarginLeft = 25;
  // doc.addImage(compressedImgTrees, 'JPG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
  doc.addImage(imgw, "JPG", imgMarginLeft, imgMarginTop, imgWidth, imgHeight);

  // Dibujar una línea amarilla debajo de la imagen
    const lineY = imgMarginTop + imgHeight + 10; // Calculamos la posición Y para la línea (debajo de la imagen)
    doc.setDrawColor(222, 168, 69); // Establecer el color amarillo (RGB)
    doc.setLineWidth(1); // Establecer el grosor de la línea
    doc.line(imgMarginLeft, lineY, imgMarginLeft + imgWidth, lineY); // Dibujar la línea

    // Agregar el texto proporcionado al PDF
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text("Itinerary Form", 90, 70);
    doc.text("Locations and Times", 80, 80);

    // Restaurar la fuente normal
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    doc.text("Main contact:", 20, 90);
    doc.text("Phone Number:", 20, 100);
    doc.text("Group Name:", 20, 110);
    doc.text("Destination:", 20, 120);
    doc.text("Start Date:", 20, 130);
    doc.text("Capacity:", 20, 140);


    // Datos de la tabla vacía
    const tableHeaders = [["Date", "Time", "Activity", "Location/Address"]];  // Encabezados
    const tableBody = [
        ["", "", "", ""],  // Celdas vacías para que se puedan llenar manualmente
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""]
    ];

    // Agregar la tabla vacía
    doc.autoTable({
        head: tableHeaders,  // Encabezado de la tabla
        body: tableBody,     // Cuerpo de la tabla con celdas vacías
        startY: 150,         // Comenzar la tabla en la posición Y indicada
        theme: 'grid',       // Estilo de cuadrícula con bordes bien definidos
        headStyles: {
        fillColor: [222, 168, 69],  // Color de fondo para el encabezado
        textColor: [255, 255, 255],  // Color del texto en el encabezado
        fontSize: 12,              // Tamaño de fuente para los encabezados
        halign: 'center'            // Alinear los encabezados al centro
        },
        bodyStyles: {
        fontSize: 10,              // Tamaño de fuente para el cuerpo de la tabla
        halign: 'center',          // Alineación horizontal de los textos (centrado)
        },
        columnStyles: {
        0: { cellWidth: 35 },  // Ancho de la primera columna (Date)
        1: { cellWidth: 30 },  // Ancho de la segunda columna (Time)
        2: { cellWidth: 60 },  // Ancho de la tercera columna (Activity)
        3: { cellWidth: 60 },  // Ancho de la cuarta columna (Location/Address)
        },
        margin: { top: 10 },
        lineColor: [0, 0, 0],  // Color de las líneas de la tabla (negro)
        lineWidth: 0.5,        // Grosor de las líneas
    });

    // Obtener los datos binarios del PDF como una cadena
    const pdfData = doc.output();

    // Codificar los datos binarios en base64
    const pdfBase64 = btoa(pdfData);

    // Devolver los datos codificados en base64
    return pdfBase64;
    
  

}

// Función para comprimir imágenes usando Canvas
const compressImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Add this line to handle CORS issues if needed
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      
      // Draw white background
      ctx.fillStyle = 'white'; // Tunnel49 color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw image on top of the white background
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      }, 'image/jpeg', 0.5); // Adjust quality here (0.5 means 50% quality)
    };
    img.src = imageUrl;
  });
};


export default itineraryConfirmation;
