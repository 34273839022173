import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsClientReservation } from '../../../services/columnsList';
import Button from '../../button'; 
import ModalC from '../../modal';
import FormConfig from './formConfig';
import Swal from "sweetalert2";
import {  getClientReservations, getDataReservations, addDataReservations, editDataReservations, deleteDataReservations } from '../../../services/ConfigReservations';
import { getClientAdventures } from '../../../services/ConfigAdventures';
// import {  getDataInvoice } from '../../../services/invoice';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [pendingAdven, setPendingAdven] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [dataAdven, setDataAdven] = useState({ rows: [] });
  const [combinedData, setCombinedData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  let { id } = useParams();
  const navigate = useNavigate();
  // const [allBookings, setAllBookings ] = useState(true);
  // const [dataInvoiceList, setDataInvoiceList] = useState({ rows: [] });



  useEffect(() => {
    if (user != null) {
      getClientReservations(id, setData, setPending);  
      getClientAdventures(id, setDataAdven, setPendingAdven);  
      // getDataInvoice(setDataInvoiceList);
    }
  }, [user]);

    useEffect(() => {
      if (!pending && !pendingAdven) {
        // if (dataAdven?.rows?.length !== 0) {
          //setData(data.rows.concat(dataAdven.rows));
          const combined = [...(data?.rows || []), ...(dataAdven?.rows || [])];
          setCombinedData(combined);
        // }
      }
    }, [data, dataAdven]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const action = (data, lastData) => {
    handleClearRows(); 
    if (edit) {
      editDataReservations(data, true, lastData);
    } else {
      addDataReservations(data, true);
    }

    // setFilterText('');
    setPending(true);
    setModal(false);
    setTimeout(() => {   
      // getDataReservations(setData, setPending, !allBookings);
      getClientReservations(id, setData, setPending);  
      getClientAdventures(id, setDataAdven, setPendingAdven);    
      // getDataInvoice(setDataInvoiceList);
    }, 500);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit reservation');
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        setPending(true);
        setData({ rows: [""] });
        deleteDataReservations(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })        
        setModal(false);
        setTimeout(() => {
          getClientReservations(id ,setData, setPending);
          getClientAdventures(id, setDataAdven, setPendingAdven);
          // handleClearRows();
        }, 500)

      }
    })
  }

  let service;

  const filteredItems = combinedData.filter((item) => {
    const filterTextLower = filterText.toLowerCase();
  
    // Validar si el texto de búsqueda coincide con adventure.label
    const matchesAdventureLabel =
      item?.adventure?.label && item?.adventure?.label.toLowerCase().includes(filterTextLower);
  
    // Validar si el texto de búsqueda coincide con shuttle.label
    const matchesShuttleLabel =
      item?.shuttle?.label && item?.shuttle?.label.toLowerCase().includes(filterTextLower);

    // Validar si el texto de búsqueda coincide con shuttle.label
    const matchesPickUp =
    item?.pickUp?.label && item?.pickUp?.label.toLowerCase().includes(filterTextLower);
  
    // Verificar si el texto ingresado coincide exactamente con "adventure" o "transport"
    service = item?.shuttle?.label ? "Transport" : "Adventure";
    const matchesSpecificWords = service.toLowerCase().includes(filterTextLower);

    // Validar si el texto de búsqueda coincide con la fecha de inicio
    const matchesStartDate =
    item?.startDate && item?.startDate.toLowerCase().includes(filterTextLower);
    
    // Validar si el texto de búsqueda coincide con la hora de inicio
    const matchesStartTime =
    item?.startTime && item?.startTime.toLowerCase().includes(filterTextLower);
    
    // Retornar true si cumple con alguna de las condiciones
    return matchesAdventureLabel || matchesShuttleLabel || matchesSpecificWords || matchesPickUp || matchesStartDate || matchesStartTime;
  });
  
  
  


  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const goToClient = () => {
    navigate(`/clients`);
  }
  const reloadPage = () => {
    window.location.reload(false);
  }

  const showOnCalendar = () =>{
    if(data){
      //navigate(`/booking/${data?.rows?.[0]?.idClient}`);
    }
    // window.location.reload(false);
  }

  return (

    <>
      <div className="text-center mt-4">
        <h1>{ data?.rows?.[0] ? (data?.rows?.[0]?.client?.label ? data?.rows?.[0]?.client?.label : "-") : ("")}</h1>
      </div>




    <div className="content">
      <div className="justify-content-between flex-row-reverse">
      <div className="gap-2 m-1">
      { data?.rows?.length > 0 ? (
            <>
            <div className="inline-row-reverse mx-5 p-1 text-end">
              <Button className="color-green btn btn-outline-secondary mx-1" onClick={() => { goToClient() }}>Go to client list.</Button>
              {/* <Button className="color-green btn btn-outline-secondary mx-3" onClick={() => { showOnCalendar()}}>Calendar View</Button> */}
            </div>
          </>
          ) : ("") }

          { 
          
          !pending ? ( 
          
          data?.rows?.[0] ?
          (
          
          <div className="m-5 p-5 pt-1">
            <DataTable
              columns={columnsClientReservation(editObj, deleteObj)}
              data={filteredItems}
              selectableRows
              onSelectedRowsChange={handleChange}
              pagination
              clearSelectedRows={toggledClearRows}
              defaultSortFieldId={9}
              defaultSortAsc={false}
              progressPending={pending}
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
            <ModalC
              show={modal}
              onHide={() => setModal(false)}
              changeSize={true}> 
              <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormConfig>
            </ModalC>
          </div>
          )

          : (
            <div className="text-center">
              <h2>The client has no reservations to display.</h2>
              <Button className="color-green btn btn-outline-secondary p-2 m-4" onClick={() => { reloadPage() }}>Load page again.</Button>
              <Button className="color-green btn btn-outline-secondary p-2 m-4" onClick={() => { goToClient() }}>Go to client list.</Button>
            </div>
          )
          ) : (<h2 className='text-center'>Loading...</h2>)
          }
        </div>
      </div>
    </div>
        </>
  );
}

export default TableConfig;
