import React, { useState, useEffect, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBell } from 'react-icons/fa';
import logo from '../assets/Logo_Icon.png';
import imgw from '../assets/Word_Logo_Color_Cream.png';
import { getDataOneUser } from '../services/auth/users';
import { UserAuth } from '../context/AuthContext';
import { initializeBookingListener } from '../services/notifications';
import { roleVisit } from '../services/Tools';
import '../style/style.css';
import '../style/navbar.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { server } from '../services/serverName/Bookings';
import { getDataNotifications, editDataNotifications } from '../services/ConfigNotifications';

const AvatarDropdown = ({ user, userInfo, handleSignOut }) => (
  <>
    {user && user.photoURL && (
      <div className="d-flex align-items-center">
        <Dropdown className="m-auto">
          <Dropdown.Toggle variant="null" className="dropdown-toggle">
            <img
              className="nav-avatar"
              src={`https://via.placeholder.com/50/12675c/FFFFFF/?text=${user.displayName.charAt(0)}`}
              alt="User Avatar"
            />
            <h5 className="username">{user.displayName}</h5>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            <Dropdown.Item href="/home">Home</Dropdown.Item>
            <Dropdown.Item href="/hybrid">Hybrid</Dropdown.Item>
            <Dropdown.Item href="/adventures">Adventure</Dropdown.Item>
            <Dropdown.Item href="/booking">Transport</Dropdown.Item>
            { userInfo?.role !== roleVisit && (
              <>
                <Dropdown.Item href="/rentals">Rental</Dropdown.Item>
                <Dropdown.Item href="/retails">Retail</Dropdown.Item>
                <Dropdown.Item href="/clients">Clients</Dropdown.Item>
                <Dropdown.Item href="/report">Report</Dropdown.Item>
                <Dropdown.Item href="/events">Events</Dropdown.Item>
                <Dropdown.Item href="/settings">Settings</Dropdown.Item>
              </>
            )}
            <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )}
  </>
);

const NotificationDropdown = ({ notifications = [], setNotifications, setUnreadCount }) => {
  const [notificationsState, setNotificationsState] = useState([]);

  useEffect(() => {
    // Inicializar el estado con las notificaciones recibidas (solo al inicio)
    if (notifications.length > 0 && notificationsState.length === 0) {
      setNotificationsState(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    // Actualizar el conteo de no leídas cuando cambie el estado
    const unreadCount = notificationsState.filter((n) => !n.read && !n.postponed).length
    setUnreadCount(unreadCount);
  }, [notificationsState, setUnreadCount]);

  const buttonStyle = {
    marginLeft: "10px",
    padding: "10px",
    backgroundColor: "#f0ad4e",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
    fontSize: "12px",
    margin: "5px",
  };

  const handlePostpone = (e, notification) => {
    e.stopPropagation(); // Evita que el clic en el botón active el onClick principal del Dropdown
  
    // Filtrar la notificación pospuesta del estado local y actualizar el campo postponed_date
    const updatedNotifications = notificationsState.map((notif) =>
      notif.id === notification.id
        ? { ...notif, postponed: true }
        : notif
    );
  
    // Actualizar las notificaciones en el estado local
    setNotificationsState(updatedNotifications);

    // Eliminar la notificación del estado (similar a la segunda versión)
    setNotificationsState((prevNotifications) =>
      prevNotifications.filter((notif) => notif.id !== notification.id)
    );

    // Actualizar las notificaciones en el padre (si es necesario)
    setNotifications(updatedNotifications);
  
    // Acción basada en el tipo de notificación
    editDataNotifications(notification, true, true);

  };

  return (
    <Dropdown.Menu
      show
      style={{ maxHeight: "300px", width: "100%", overflowY: "auto" }}
    >
      {notificationsState.length > 0 ? (
        notificationsState.map((notification) => {
          const { id, idService, type, message } = notification;

          if (!message) {
            console.warn(`Notification with ID ${id} has no message.`);
            return null;
          }

          return (
            <div className="d-flex align-items-center" key={id}>
              <Dropdown.Item
                onClick={() =>
                  (window.location.href = `/${
                    type === "adventure" ? ("adventures") : type === "adventure-no-deposit" ? ("adventures") : ("booking")
                  }/${idService}`)}
              >
                {message}
              </Dropdown.Item>
              <button
                onClick={(e) => handlePostpone(e, notification)}
                style={buttonStyle}
              >
                Postpone
              </button>
            </div>
          );
        })
      ) : (
        <Dropdown.Item disabled>No new notifications</Dropdown.Item>
      )}
    </Dropdown.Menu>
  );
};

const NotificationBell = ({ unreadCount, onClick }) => (
  <div
    className="notification-bell"
    style={{ marginRight: '10px',  marginTop: '100%', position: 'relative', cursor: 'pointer' }}
    onClick={onClick}
  >
    <FaBell size={24} color={unreadCount > 0 ? 'red' : 'white'} />
    {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
  </div>
);

const Navbars = () => {
  const { user, logOut } = UserAuth();
  const [userInfo, setUserInfo] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationListAux, setNotificationListAux] = useState([]);
  const [notificacionList, setNotificationList] = useState([{ id: '', idService: '', title: '', type:'', message: '', postponed: '', postponed_date: '' }]);

  const dropdownRef = useRef(null); // Referencia para el dropdown
  //const unreadCount = notificacionList.filter((n) => !n.read).length;
  const [unreadCount, setUnreadCount] = useState(0);
  const screenWidth = window.innerWidth;
  
  useEffect(() => {
    setUnreadCount(
      notificacionList.filter((n) => !n.read && !n.postponed).length 
    );
  }, [notificacionList]);  // Este useEffect ahora solo cuenta las no leídas que no están pospuestas
  

  useEffect(() => {
    const getUserData = async () => {
      const userDocSnap = await getDataOneUser(user.uid); 
      if (userDocSnap?.length > 0) {
        setUserInfo(userDocSnap[0]);
      }
    };
    if (user) {
      getUserData();
    }
  }, [user]);

  useEffect(() => {
    if (user != null) {
      getDataNotifications((data) => {
        setNotificationListAux(data);
      });
    }
  }, [user]);

  //Use by notification List
  useEffect(() => {
    if (notificationListAux?.rows && Array.isArray(notificationListAux.rows)) {
      const aux = notificationListAux.rows.map(element => ({
        id: element?.id || "Untitled", // Valor por defecto si falta
        idService: element?.idService || "Undefine", // Valor por defecto si falta
        title: element?.title || "Untitled", // Valor por defecto si falta
        type: element?.type || "Untitled", // Valor por defecto si falta
        message: element?.message || "No message provided", // Valor por defecto
        postponed: element?.postponed || false,
        postponed_date: element?.postponed_date || null,
      }));
      setNotificationList(aux);

      // const unreadCount = notificationListAux.filter((n) => !n.read).length;
      // setUnreadCount(unreadCount);
    }
  }, [notificationListAux]);
  

  useEffect(() => {
    let unsubscribe;
    if (user) {
      unsubscribe = initializeBookingListener((newNotification) => {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          { ...newNotification, read: false, postponed: false }
        ]);
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  const handleSignOut = useCallback(async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  }, [logOut]);

  const handleBellClick = useCallback(() => {
    setShowNotifications((prev) => !prev); // Alterna la visibilidad de las notificaciones
  
    // Solo actualiza el estado si las notificaciones están visibles
    if (!showNotifications) {
      setNotifications((prev) =>
        prev.map((n) => ({ ...n, read: true, postponed: true }))
      );
    }
  }, [showNotifications]); // Dependencia agregada para controlar el estado de las notificaciones

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
  }, []);

  useEffect(() => {
    if (showNotifications) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications, handleClickOutside]);

  const dropdownStyle = {
    position: 'absolute',
    top: screenWidth <= 480 ? '50%' : '100%',
    left: screenWidth <= 480 ? '50%' : '50%',
    transform: screenWidth <= 480 ? 'translate(-50%, -50%)' : 'translateX(-50%)',
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: screenWidth <= 480 ? '300px' : '380px', // Ancho ajustado para móviles
    marginTop: screenWidth <= 480 ? '0' : '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para diseño más limpio
  };

  return (
    <Navbar className="navbar" expand="lg">
      <Container>
        <Navbar.Brand className="col-md-2">
          <img className="logo" src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Brand className="col-md-8 m-auto">
          
          { server === 'dev' ? 
            (<h1 className='text-center ml-2 text-warning fs-1 fs-md-2 fs-lg-3'>THIS IS A TEST MODE</h1>)
            : 
            (<img className="imag" src={imgw} alt="Logo" />)
          }
        </Navbar.Brand>
        <Navbar.Brand
            className="d-flex col-12 col-md-2 justify-content-center"
            style={{ position: 'relative', margin: 'auto' }}
          >
          { userInfo?.role !== roleVisit ? (
            <>
            {user && user.photoURL ? (
              <div style={{ position: 'relative' }}>
                <NotificationBell unreadCount={unreadCount} onClick={handleBellClick} />
                {showNotifications && (
                  <div
                    className="notification-dropdown"
                    ref={dropdownRef}
                    style={dropdownStyle}
                  >  
                    <NotificationDropdown 
                      notifications={notificacionList.filter((n) => !n.postponed)} // Filtrar notificaciones no pospuestas
                      setNotifications={setNotifications} 
                      setUnreadCount={setUnreadCount}
                    />
                  </div>
                )}
              </div>
            ) : "" }
            </>
          ) : ("")}

            <AvatarDropdown user={user} userInfo={userInfo} handleSignOut={handleSignOut} />
        </Navbar.Brand>

      </Container>
      <ToastContainer />

    </Navbar>
  );
};

export default Navbars;
